import React from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import { infoTilePositionState } from "./state/info-tile-position.state";
import { gameConstsState } from "./state/game-consts.state";

const getPositionStyle = ({ h, v, width, height, x, y }) => {
	console.log("{ x, y, width, heigth }", { x: h, y: v, width, height });
	const whalf = Number(width) / 2;
	const hhalf = Number(height) / 2;
	if (h <= whalf && v <= hhalf) {
		return {
			top: `${y}px`,
			left: `${x}px`,
		};
	}
	if (h <= whalf && v > hhalf) {
		return {
			bottom: `${window.innerHeight - y}px`,
			left: `${x}px`,
		};
	}
	if (h > whalf && v <= hhalf) {
		return {
			top: `${y}px`,
			right: `${window.innerWidth - x}px`,
		};
	}
	if (h > whalf && v > hhalf) {
		return {
			bottom: `${window.innerHeight - y}px`,
			right: `${window.innerWidth - x}px`,
		};
	}
};

const InfoCard = ({ info }) => {
	const [infoTilePosition, setInfoTilePosition] = useRecoilState(
		infoTilePositionState
	);
	const [gameConsts, setGameConsts] = useRecoilState(gameConstsState);
	const cardPosition = getPositionStyle({
		...infoTilePosition,
		...gameConsts,
	});
	console.log("-0-dsf0-sd0-f-sdf-0", gameConsts);
	// alert(`${cardPosition.top}, ${cardPosition.bottom}`);
	const cardStyle = {
		padding: "2.5rem 1rem 2rem 1rem",
		display: "inline-flex",
		gap: "1.5 rem",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		// background: "#fff",
		color: infoTilePosition?.isYang ? "black" : "white",
		background: infoTilePosition?.isYang ? "white" : "black",
		position: "absolute",
		// textAlign: "center",
		...cardPosition,
		width: "14.5rem",
		height: "14.5rem",
		margin: "1rem",
		"@media (max-width: 1440px)": {
			margin: "0.5rem",
		},
		"@media (max-width: 2560px)": {
			margin: "1rem",
		},
	};
	if (!infoTilePosition || !infoTilePosition.x || !infoTilePosition.y)
		return <></>;
	return (
		<div style={cardStyle}>
			<p style={{ fontSize: "0.75rem", lineHeight: "1.5625rem" }}>CHING</p>
			<div>
				<p
					style={{
						fontSize: "2rem",
						lineHeight: "1.5625rem",
						paddingBottom: "1.5rem",
					}}
				>
					H{infoTilePosition.h + 1}V{infoTilePosition.v + 1}
				</p>
			</div>
			<div>
				<a
					href={infoTilePosition.link}
					target="_blank"
					className="paragraph extra-small your-link-text"
					rel="noopener noreferrer"
				>
					{infoTilePosition.link}
				</a>
			</div>
		</div>
	);
};

export default InfoCard;
