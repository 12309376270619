import { useState, forwardRef, useImperativeHandle } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import handleHideClass from "../common/hide-class-helper";

import { balanceState } from "../state/balance.state";
import { uiModeState, modes } from "../state/uiMode.state";
import { gameConstsState } from "../state/game-consts.state";
import { playerInfoState } from "../state/player-info.state";
import { blockSelectedLoadedState } from "../state/block-selected-loaded.state";

const scaleBalanceValueToPercents = (originalValue) => {
	let normalized = (originalValue + 1) / 2;
	let scaled = 1 + normalized * 98;
	return scaled;
};

const mockArr = [{}];

// side could be "yang" or "yin" string value
const Replay = forwardRef(
	({ changeSpeed, speed, stop, start, isDrawing }, ref) => {
		useImperativeHandle(ref, () => ({
			someFunction() {},
		}));

		const [uiMode, setUiMode] = useRecoilState(uiModeState);
		const [balance, setBalance] = useRecoilState(balanceState);
		const isHide = uiMode !== modes.replay;
		const playerInfo = useRecoilValue(playerInfoState);
		const blockSelectedLoaded = useRecoilValue(blockSelectedLoadedState);
		const [gameConsts, setGameConsts] = useRecoilState(gameConstsState);

		const popupClass = isHide ? "top-panel hidden" : "top-panel visible";
		return (
			<div className={popupClass}>
				<div className="div-2-3">
					<p className={"paragraph extra-small replay-label"}>replay</p>
				</div>
				<div className={"div-1-3 align-right"}>
					<button
						onClick={() => setUiMode(modes.default)}
						id="w-node-_8f76eadb-8808-8946-5cbe-ff9822c8b5cb-54442f70"
						className="button blue exit"
					>
						exit replay
					</button>
				</div>

				<div className={"column-3 replay-main"}>
					{isDrawing ? (
						<div
							id="w-node-a6bdbf86-528d-8823-0996-187a6adcf71c-54442f70"
							className="play w-embed"
							onClick={() => stop()}
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewbox="0 0 24 24"
								fill="none"
							>
								<rect x="5" width="5" height="24" fill="black"></rect>
								<rect x="14" width="5" height="24" fill="black"></rect>
							</svg>
						</div>
					) : (
						<div
							id="w-node-a6bdbf86-528d-8823-0996-187a6adcf71c-54442f70"
							className="play w-embed"
							onClick={() => start()}
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
							>
								<path d="M21 12L5 24L5 -4.95396e-07L21 12Z" fill="black" />
							</svg>
						</div>
					)}
					<div
						id="w-node-_3564e758-06ff-45c0-7369-6ef5d43a8bf2-54442f70"
						className="replay-line-wrap"
					>
						<div className="replay-line"></div>
						<div className="replay-line-vertical"></div>
					</div>
					<div
						id="w-node-cc4be37e-14bb-d89a-c377-dc28b67587a7-54442f70"
						className="speed-btn-wrap"
					>
						<button
							id="w-node-_8f76eadb-8808-8946-5cbe-ff9822c8b5cb-54442f70"
							className={"button" + (speed === 1000 ? " blue" : "")}
							onClick={() => {
								changeSpeed(1000);
							}}
						>
							1x
						</button>
						<button
							id="#w-node-_3564e758-06ff-45c0-7369-6ef5d43a8c099-54442f70"
							onClick={() => changeSpeed(500)}
							className={"button" + (speed === 500 ? " blue" : "")}
						>
							2x
						</button>

						<button
							id="w-node-bc629063-85a6-b18f-2f16-d99b48b09db8-54442f70"
							onClick={() => changeSpeed(250)}
							className={"button" + (speed === 250 ? " blue" : "")}
						>
							4x
						</button>
					</div>
				</div>
				<div className="column-3 between">
					<div
						id="w-node-_3564e758-06ff-45c0-7369-6ef5d43a8bf1-54442f70"
						className="polygon w-embed"
						style={{
							left: `${scaleBalanceValueToPercents(balance)}%`,
						}}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="12"
							height="9"
							viewBox="0 0 12 9"
							fill="none"
						>
							<path
								d="M6 9L0.803849 -9.78799e-07L11.1962 -7.02746e-08L6 9Z"
								fill={Math.abs(balance) <= 0.5 ? "#4EFF3F" : "#F67052"}
							></path>
						</svg>
					</div>
					<div
						id="w-node-_3564e758-06ff-45c0-7369-6ef5d43a8bf2-54442f70"
						className="yinyangbalance"
					>
						<div className="yinyang-wrapper">
							<div className="yang-line">
								<div className="vline white"></div>
								<div className="vlinemini white"></div>
							</div>
							<div className="yin-line">
								<div className="vline attach"></div>
								<div className="vlinemini"></div>
							</div>
						</div>
						<div className="balancetext-wrapper">
							<p className="label-text white-text">
								1 yang
								<br />
							</p>
							<p className="label-text">
								DAO in dynamic balance
								<br />
							</p>
							<p className="label-text">
								1 yin
								<br />
							</p>
						</div>
					</div>
				</div>
			</div>
		);
	}
);

export default Replay;
