// import { balanceState } from "../state/balance.state";
// import { yangYinChingsState } from "../state/yang-yin-chings.state";

const isBalance = (yangYinChings) => {
	const yangAmount = Object.keys(yangYinChings?.yang).length;
	const yinAmount = Object.keys(yangYinChings?.yin).length;

	const formula = (yang, yin) =>
		Math.tanh((32 * (yin - yang)) / (21280 - yin - yang));

	const result = formula(yangAmount, yinAmount);
	return result >= -0.5 && result <= 0.5;
};
function GameFooter({ yangYinChings, panelWidth }) {
	// const yangYinChings = useRecoilValue(yangYinChingsState);
	// const balance = useRecoilValue(balanceState);
	// const isBalance = () => balance <= 0.5 && balance >= -0.5;
	return (
		<div
			id="ddc-game-footer"
			className="game-footer"
			style={{
				width: panelWidth,
			}}
		>
			<div className="together-div">
				<div className="ill-text-line margin-bottom">
					<div
						className={
							"status-ellipse " + (isBalance(yangYinChings) ? "" : "orange")
						}
					></div>
					<p className="paragraph meta">
						DAO is{" "}
						{isBalance(yangYinChings)
							? "balanced"
							: "imbalanced: remapping unlocked"}
						:{" "}
						<span className="white-text">
							{Object.keys(yangYinChings.yang).length} YANG
						</span>{" "}
						| {Object.keys(yangYinChings.yin).length} YIN
						<br />
					</p>
				</div>
				<p className="paragraph meta">
					<span className="white-text">
						{(
							10640 -
							Object.keys(yangYinChings.yang).length -
							Object.keys(yangYinChings.yin).length
						).toLocaleString()}{" "}
						CHINGS{" "}
					</span>
					to be manifested until the game over
					<br />
				</p>
			</div>
			<p className="paragraph meta">
				Twitter (X):&nbsp;
				<a
					href="https://twitter.com/daodechinggame"
					target="_blank"
					rel="noopener noreferrer"
					className="white-text"
				>
					@daodechinggame
				</a>
			</p>
		</div>
	);
}

export default GameFooter;
