import { useRecoilValue, useRecoilState } from "recoil";

import convertIdToXY from "../common/convert-id-to-XY";
import { uiModeState, modes } from "../state/uiMode.state";
import { yourChingsState } from "../state/your-chings.state";
import { myBlockSelectedState } from "../state/my-block-selected.state";
import { processingBlocksState } from "../state/processing-blocks.state";
import { myBlockHoveredIdState } from "../state/my-block-hovered-id.state";
import { canvasRenderState } from "../state/canvas-render-mode.state";

import handleHideClass from "../common/hide-class-helper";

function Manymanymanyblocksowned({ chings, processingBlocks }) {
	// const [processingBlocks, setProcessingBlocks] = useRecoilState(
	// 	processingBlocksState
	// );
	const [myBlockHoveredId, setMyBlockHoveredId] = useRecoilState(
		myBlockHoveredIdState
	);
	const yourChings = useRecoilValue(yourChingsState);
	const [myBlockSelected, setMyBlockSelected] =
		useRecoilState(myBlockSelectedState);
	const [uiMode, setUiMode] = useRecoilState(uiModeState);
	const isHide = uiMode !== modes.yourChings;

	const [canvasRender, setCanvasRender] = useRecoilState(canvasRenderState);
	const topPanelClass = isHide ? "hidden" : "visible";
	const contentClass = isHide ? "fade-out" : "fade-in";
	const handleBlockClicked = (chingId) => () => {
		console.log("=--0-0-0-0-0-0-0-0", yourChings[chingId]);
		setMyBlockSelected(() => yourChings[chingId]);
		setUiMode(() => modes.yourChingSelected);
		setCanvasRender((oldV) => ({
			...oldV,
			aim: convertIdToXY(chingId),
			drawMode: "yours",
		}));
	};
	return (
		<div className={`top-panel ${topPanelClass}`}>
			<div className={"div-2-3"}>
				<div className={"yours-wrapper"}>
					<div className="yours-grid hide">
						{Object.keys(yourChings)?.map((chingId) => (
							<p
								id="w-node-_3564e758-06ff-45c0-7369-6ef5d43a8a8e-54442f70"
								className="yours"
								onClick={handleBlockClicked(chingId)}
								onMouseEnter={() => {
									setMyBlockHoveredId(convertIdToXY(chingId));
									setCanvasRender((oldV) => ({
										...oldV,
										hoverAim: convertIdToXY(chingId),
										drawMode: "default",
									}));
								}}
								onMouseLeave={() => {
									setMyBlockHoveredId(null);
									setCanvasRender((oldV) => ({
										...oldV,
										hoverAim: null,
										drawMode: "default",
									}));
								}}
							>
								H{(chingId % 152) + 1}V{Math.floor(chingId / 152) + 1}
								<br />
							</p>
						))}
						{processingBlocks
							.map((pb) => pb.chingId)
							.map((chingId) => (
								<p
									id="w-node-_3564e758-06ff-45c0-7369-6ef5d43a8a8e-54442f70"
									className="yours"
									onMouseEnter={() => {
										setMyBlockHoveredId(convertIdToXY(chingId));
										setCanvasRender((oldV) => ({
											...oldV,
											hoverAim: convertIdToXY(chingId),
											drawMode: "default",
										}));
									}}
									onMouseLeave={() => {
										setMyBlockHoveredId(null);
										setCanvasRender((oldV) => ({
											...oldV,
											hoverAim: null,
											drawMode: "default",
										}));
									}}
								>
									H{(chingId % 152) + 1}V{Math.floor(chingId / 152) + 1}{" "}
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="6"
										height="6"
										viewBox="0 0 6 6"
										fill="none"
									>
										<circle cx="3" cy="3" r="3" fill="#FFB802" />
									</svg>
									<br />
								</p>
							))}
					</div>
					{/* {<div className="hr horizontal"></div>} */}
				</div>
			</div>
			<div className={" div-1-3 align-right"}></div>
		</div>
	);
}

export default Manymanymanyblocksowned;
