import { useRecoilValue, useRecoilState } from "recoil";

import { uiModeState, modes } from "../state/uiMode.state";
import { playerInfoState } from "../state/player-info.state";
import { myBlockSelectedState } from "../state/my-block-selected.state";

function YourChingSelected() {
	const myBlockSelected = useRecoilValue(myBlockSelectedState);
	const playerInfo = useRecoilValue(playerInfoState);

	const [uiMode, setUiMode] = useRecoilState(uiModeState);
	const isHide = uiMode !== modes.yourChingSelected;
	const topPanelClass = isHide ? "hidden" : "visible";
	return (
		<div className={`top-panel ${topPanelClass}`}>
			<div className="div-2-3">
				<div className="div-card-block-show-yours">
					<div className="together-div align-center">
						<p className="paragraph extra-small">
							<span className="block-label">
								H{(myBlockSelected?.chingId % 152) + 1}V
								{Math.floor(myBlockSelected?.chingId / 152) + 1}
							</span>
							<br />
						</p>
						<p
							className={`label-card-${
								myBlockSelected?.isYang ? "yang" : "yin"
							}`}
						>
							yours
							<br />
						</p>
					</div>
					<a
						href={myBlockSelected?.link}
						className="yours-link"
						target="_blank"
						rel="noopener noreferrer"
					>
						{myBlockSelected?.link}
					</a>
					<div className="together-div">
						<a
							href="https://twitter.com/daodechinggame"
							target="_blank"
							rel="noopener noreferrer"
							className="button blue margin-right"
						>
							share on X
						</a>
						<button
							onClick={() => {
								navigator.clipboard.writeText(
									`https://daodechinggame.com?id=${myBlockSelected?.chingId}`
								);
							}}
							className="button"
						>
							copy link to this CHING
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default YourChingSelected;
