import Web3 from "web3";
import { abi } from "./contract.abi";

// Mainnet contract address
export const contractAddress = "0x4a6b83A1ba24D1cEA649924b3190c03f255cC30a";
// export const contractAddress = "0x594d334995f82574fc58371349E87e78da6aC00d";

export const getContract = async () => {
	if (window.ethereum) {
		const requestRes = await window.ethereum.request({
			method: "eth_requestAccounts",
		});
		const web3 = new Web3(window.ethereum);
		const contract = new web3.eth.Contract(abi, contractAddress);
		return { contract, playerAddress: requestRes[0] };
	} else {
		console.log("Please install MetaMask!");
		return null;
	}
};

export const getInfuraContract = () => {
	const infuraUrl =
		"https://polygon-mainnet.infura.io/v3/7d427c023753483eb1de4a3afa1cdb4b";
	const web3 = new Web3(infuraUrl);
	const contract = new web3.eth.Contract(abi, contractAddress);
	return contract;
};

export const getInfuraWSContract = () => {
	const infuraUrl =
		"wss://polygon-mainnet.infura.io/ws/v3/7d427c023753483eb1de4a3afa1cdb4b";
	const web3 = new Web3(infuraUrl);
	const contract = new web3.eth.Contract(abi, contractAddress);
	return contract;
};

export const getContractLoggedInEarlier = async () => {
	console.log("getContractLoggedInEarlier");
	if (window.ethereum) {
		const requestRes = await window.ethereum.request({
			method: "eth_accounts",
		});
		console.log("-------checking already logged in------", requestRes[0]);
		if (!requestRes || !requestRes.length || !requestRes.length > 0)
			return { contract: "", address: "" };
		const web3 = new Web3(window.ethereum);
		const contract = new web3.eth.Contract(abi, contractAddress);
		return { contract, address: requestRes[0] };
	} else {
		console.log("Please install MetaMask!");
		return { contract: "", address: "" };
	}
};

export const buyBlock = async () => {
	if (window.ethereum) {
		await window.ethereum.request({ method: "eth_requestAccounts" });
		const web3 = new Web3(window.ethereum);
		const contract = new web3.eth.Contract(abi, contractAddress);
		return contract;
	} else {
		console.log("Please install MetaMask!");
		return null;
	}
};
