import { useState } from "react";
import { useRecoilValue } from "recoil";

import handleHideClass from "../common/hide-class-helper";

import { uiModeState, modes } from "../state/uiMode.state";
import { playerInfoState } from "../state/player-info.state";

function ManifestedBlockPopup({ block, isHide, manualClosed, playerSide }) {
	const handleCloseClick = () => manualClosed();
	// console.log("---23-0-4-----------", block);
	return (
		<div className={"div-centered pop" + handleHideClass(isHide)}>
			<div
				className={
					"popup-wrapper " + (playerSide === "yin" ? "yin-bg" : "yang-bg")
				}
			>
				{block?.isFinished === true && block?.result === "error" && (
					<div className="pop-error">
						<p>
							Someone was first and this CHING is not available for the
							operation anymore. Try another one!
						</p>
					</div>
				)}
				<div className="div-2-3">
					<p className="block-label-heading">
						H{block?.y}V{block?.x}
						<br />
					</p>
					{block?.isFinished && block?.result === "success" && (
						<p className="paragraph extra-small">
							<span className="paragraph extra-small">
								manifested as {playerSide?.toUpperCase()}
							</span>
							<br />
						</p>
					)}
				</div>
				<div className="div-1-3 align-right">
					<button
						className={"button " + (playerSide === "yin" ? "white" : "")}
						onClick={handleCloseClick}
					>
						X
					</button>
				</div>
				<div className="link-popup-wrapper">
					{block?.isFinished === false && (
						<p className="paragraph extra-small alert-text">
							Awaiting confirmation ... <br />
							May take up to several minutes.
						</p>
					)}
					{block?.isFinished && block?.result === "success" && (
						<p className="paragraph extra-small">
							<a
								href={block?.link}
								className="paragraph extra-small link-text"
								target="_blank"
								rel="noopener noreferrer"
							>
								{block?.link}
							</a>
							<br />
						</p>
					)}
				</div>
				{block?.isFinished && block?.result === "success" && (
					<div className="link-popup-wrapper">
						<p className="paragraph extra-small margin-bottom">
							Share the link to your block for the world to see.
							<br />
						</p>

						<div className="column-2 stretch">
							<button
								onClick={() => {
									navigator.clipboard.writeText(
										`https://daodechinggame.com?id=${block?.chingId}`
									);
								}}
								className={`button ${
									playerSide === "yin" ? "white" : ""
								} full-width`}
							>
								copy link to this CHING
							</button>
							<a
								href="https://twitter.com/daodechinggame"
								target="_blank"
								rel="noopener noreferrer"
								className="button blue"
							>
								share on X
							</a>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}

export default ManifestedBlockPopup;
