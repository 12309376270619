import { useRecoilValue, useRecoilState } from "recoil";

import { uiModeState, modes } from "../state/uiMode.state";
import { playerInfoState } from "../state/player-info.state";
import { blockFromUrlParamsState } from "../state/block-from-url-params.state";

function BlockFromUrl() {
	const blockFromUrlParams = useRecoilValue(blockFromUrlParamsState);
	const playerInfo = useRecoilValue(playerInfoState);

	const [uiMode, setUiMode] = useRecoilState(uiModeState);
	const isHide = uiMode !== modes.paramBlock;
	const topPanelClass = isHide ? "hidden" : "visible";
	return (
		<div className={`top-panel ${topPanelClass}`}>
			<div className="div-2-3">
				<div className="div-card-block-show-yours">
					<div className="together-div align-center">
						<p className="paragraph extra-small">
							<span className="block-label">
								H{(blockFromUrlParams?.chingId % 152) + 1}V
								{Math.floor(blockFromUrlParams?.chingId / 152) + 1}
							</span>
							<br />
						</p>
						{/* TODO: add playerSide.address === owner */}
						{blockFromUrlParams?.owner?.toUpperCase() ===
							playerInfo?.address?.toUpperCase() && (
							<p
								className={`label-card-${
									blockFromUrlParams?.isYang ? "yang" : "yin"
								}`}
							>
								yours
								<br />
							</p>
						)}
					</div>
					<a
						href={blockFromUrlParams?.link}
						className="yours-link"
						target="_blank"
						rel="noopener noreferrer"
					>
						{blockFromUrlParams?.link}
					</a>
					<div className="together-div">
						<a
							href="https://twitter.com/daodechinggame"
							target="_blank"
							rel="noopener noreferrer"
							className="button blue margin-right"
						>
							share on X
						</a>
						<button
							onClick={() => {
								navigator.clipboard.writeText(
									`https://daodechinggame.com?id=${blockFromUrlParams?.chingId}`
								);
							}}
							className="button"
						>
							copy link to this CHING
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default BlockFromUrl;
