import { useEffect, useState, useCallback, useRef } from "react";
import { WalletConnectModal } from "@walletconnect/modal";

import loaderImg from "./loader.png";
import "./webflow-assets/css/normalize.css";
import "./webflow-assets/css/dao-de-ching.css";
import "./webflow-assets/css/components.css";

import Replay from "./components/Replay";
import RenderEngine from "./RenderEngine";
import GameFooter from "./components/GameFooter";
import BlockFromUrl from "./components/BlockFromUrl";
import ReplayCanvas from "./components/ReplayCanvas";
import convertIdToXY from "./common/convert-id-to-XY";
import ManifestBlock from "./components/ManifestBlock";
import RemappingPanel from "./components/RemappingPanel";
import ChooseSidePopup from "./components/ChooseSidePopup";
import PotentiateBlock from "./components/PotentiateBlock";
import DescriptionPopup from "./components/DescriptionPopup";
import MobileDescription from "./components/MobileDescription";
import ManifestedBlockPopup from "./components/ManifestedBlockPopup";
import PotentiatedBlockPopup from "./components/PotentiatedBlockPopup";
import ManifestedBlockSelected from "./components/ManifestedBlockSelected";
import Manymanymanyblocksowned from "./components/Manymanymanyblocksowned";
import Mapping from "./components/Mapping";
import Remapping from "./components/Remapping";
import YourChingSelected from "./components/YourChingSelected";
import { useRecoilState, useRecoilValue } from "recoil";
import Cookies from "js-cookie";
import Web3 from "web3";
import InfoCard from "./InfoCard";
import { ethers } from "ethers";
import {
	createWeb3Modal,
	defaultConfig,
	useWeb3Modal,
	useWeb3ModalProvider,
	useWeb3ModalAccount,
} from "@web3modal/ethers5/react";

import { abi } from "./services/contract.abi";
import { dimensionsState } from "./state/dimensions.state";
import { blockInfosState } from "./state/blockInfos.state";
import { renderModeState } from "./state/renderMode.state";
import { playerInfoState } from "./state/player-info.state";
import { gameConstsState } from "./state/game-consts.state";
import { uiModeState, modes } from "./state/uiMode.state";
import { yourChingsState } from "./state/your-chings.state";
import { yangYinChingsState } from "./state/yang-yin-chings.state";
import { canvasRenderState } from "./state/canvas-render-mode.state";
import { myBlockSelectedState } from "./state/my-block-selected.state";
import { infoTilePositionState } from "./state/info-tile-position.state";
import { blockFromUrlParamsState } from "./state/block-from-url-params.state";
import { blockSelectedLoadedState } from "./state/block-selected-loaded.state";
import { manifestedBlockSelectedState } from "./state/manifested-block-selected.state";
import { blockSelectedByCanvasClickState } from "./state/block-selected-by-canvas-click.state";
import { balanceState } from "./state/balance.state";

import {
	getContract,
	getInfuraContract,
	getInfuraWSContract,
	contractAddress,
	getContractLoggedInEarlier,
} from "./services/contract.service";
import handleHideClass from "./common/hide-class-helper";

const isTouchDevice = () => {
	return (
		"ontouchstart" in window ||
		navigator.maxTouchPoints > 0 ||
		navigator.msMaxTouchPoints > 0
	);
};

const getYangYinChings = (blockInfos) => {
	const yangChings = Object.keys(blockInfos)?.reduce((newObj, key) => {
		if (
			blockInfos[key].owner &&
			blockInfos[key].isYang &&
			blockInfos[key].hasLink
		) {
			newObj[key] = blockInfos[key];
		}
		return newObj;
	}, {});

	const yinChings = Object.keys(blockInfos)?.reduce((newObj, key) => {
		if (
			blockInfos[key].owner &&
			!blockInfos[key].isYang &&
			blockInfos[key].hasLink
		) {
			newObj[key] = blockInfos[key];
		}
		return newObj;
	}, {});

	return { yang: yangChings, yin: yinChings };
};

const getBalance = (yangYinChings) => {
	const yangAmount = Object.keys(yangYinChings?.yang).length;
	const yinAmount = Object.keys(yangYinChings?.yin).length;

	const formula = (yang, yin) =>
		Math.tanh((32 * (yin - yang)) / (21280 - yin - yang));

	const result = formula(yangAmount, yinAmount);
	return result;
};

const removeChars = (str, start, end) => {
	if (!str) return;
	if (start < 0 || start >= str.length) {
		return str;
	}
	end = Math.min(end, str.length);
	return str.substring(0, start) + "..." + str.substring(end);
};

const connectWalletConfig = () => {
	const projectId = "dcc8fa6053636f147b413ea25a6005e3";
	const polygonMainnet = {
		chainId: 137, // Polygon Mainnet's chain ID
		name: "Polygon",
		currency: "MATIC",
		explorerUrl: "https://polygonscan.com", // Polygon block explorer
		rpcUrl: "https://polygon-rpc.com", // RPC URL for Polygon Mainnet
	};
	const metadata = {
		name: "DAO DE CHING",
		description: "DAO DE CHING GAME",
		url: "https://dao-de-ching.web.app/",
		icons: [
			"https://www.google.com/url?sa=i&url=https%3A%2F%2Fcommons.wikimedia.org%2Fwiki%2FFile%3AHow_to_use_icon.svg&psig=AOvVaw18B1UOIEiUfrUkU2zmpyr2&ust=1705586709678000&source=images&cd=vfe&opi=89978449&ved=0CBMQjRxqFwoTCPD6quLL5IMDFQAAAAAdAAAAABAD",
		],
	};

	return {
		ethersConfig: defaultConfig({ metadata }),
		chains: [polygonMainnet],
		defaultChain: polygonMainnet,
		projectId,
		includeWalletIds: [
			"c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96",
		],
		featuredWalletIds: [
			"c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96",
			"4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0",
		],
		excludeWalletIds: [
			"225affb176778569276e484e1b92637ad061b01e13a048b35a9d280c3b58970f",
		],
	};
};

function App() {
	const GRID_WIDTH = 0;
	const PADDING_BLOCKS = 4;
	const canvasRef = useRef();
	const [blockSize, setBlockSize] = useState(-1);
	const drawBlock = (context, x, y, isYang, deCount) => {
		if (blockSize <= 0) return;
		if (deCount === 0) {
			context.fillStyle = "#ACACAC";
			context.fillRect(
				(x + PADDING_BLOCKS) * blockSize,
				(y + PADDING_BLOCKS) * blockSize,
				blockSize - GRID_WIDTH,
				blockSize - GRID_WIDTH
			);
			return;
		}
		if (deCount === 1n) {
			context.fillStyle = isYang ? "#FFFFFF" : "#000000";
			context.fillRect(
				(x + PADDING_BLOCKS) * blockSize,
				(y + PADDING_BLOCKS) * blockSize,
				blockSize - GRID_WIDTH,
				blockSize - GRID_WIDTH
			);

			context.beginPath();
			context.fillStyle = isYang ? "#000000" : "#FFFFFF";

			let centerX = (x + PADDING_BLOCKS) * blockSize + blockSize / 2;
			let centerY = (y + PADDING_BLOCKS) * blockSize + blockSize / 2;
			let radius = (blockSize * 5) / 18;

			context.arc(centerX, centerY, radius, 0, 2 * Math.PI);
			context.fill();
		} else if (deCount > 1n) {
			context.fillStyle = isYang ? "#FFFFFF" : "#000000";
			console.log(
				(x + PADDING_BLOCKS) * blockSize,
				(y + PADDING_BLOCKS) * blockSize,
				blockSize - GRID_WIDTH,
				blockSize - GRID_WIDTH,
				isYang ? "#FFFFFF" : "#000000"
			);
			context.fillRect(
				(x + PADDING_BLOCKS) * blockSize,
				(y + PADDING_BLOCKS) * blockSize,
				blockSize - GRID_WIDTH,
				blockSize - GRID_WIDTH
			);
		}
	};

	const setUpCanvas = (canvas) => {
		const context = canvas.getContext("2d");
		const blocksWrapper = canvas.parentElement;
		const paddedWidth = Number(gameConsts.width) + PADDING_BLOCKS * 2;
		const paddedHeight = Number(gameConsts.height) + PADDING_BLOCKS * 2;
		const blockSizeCalc = Math.round(
			Math.min(
				blocksWrapper.offsetWidth / paddedWidth,
				blocksWrapper.offsetHeight / paddedHeight
			)
		);
		setBlockSize(() => blockSizeCalc);

		canvas.width = blockSizeCalc * paddedWidth;
		canvas.height = blockSizeCalc * paddedHeight;

		const devicePixelRatio = window.devicePixelRatio || 1;
		const backingStoreRatio =
			context.webkitBackingStorePixelRatio ||
			context.mozBackingStorePixelRatio ||
			context.msBackingStorePixelRatio ||
			context.oBackingStorePixelRatio ||
			context.backingStorePixelRatio ||
			1;
		const ratio = devicePixelRatio / backingStoreRatio;

		const oldBlocksWidth = canvas.width;
		const oldBlocksHeight = canvas.height;
		if (devicePixelRatio !== backingStoreRatio) {
			canvas.width = oldBlocksWidth * ratio;
			canvas.height = oldBlocksHeight * ratio;

			handleUpdatePanelWidth(
				canvas.style.width.replace("px", "") - PADDING_BLOCKS * 2 * blockSize
			);
			canvas.style.width = oldBlocksWidth + "px";
			canvas.style.height = oldBlocksHeight + "px";

			context.scale(ratio, ratio);
		}
		canvas.style.width = oldBlocksWidth + "px";
		canvas.style.height = oldBlocksHeight + "px";
		handleUpdatePanelWidth(
			canvas.style.width.replace("px", "") - PADDING_BLOCKS * 2 * blockSize
		);
	};

	const [isCanvInit, setIsCanvInit] = useState(false);
	useEffect(() => {
		if (!canvasRef.current || !!isCanvInit) return;
		const canvas = canvasRef.current;
		setUpCanvas(canvas);
		const context = canvas.getContext("2d");
		if (blockSize <= 0) return;
		setIsCanvInit(() => true);
		for (let y = 0; y < Number(gameConsts.height); y++) {
			for (let x = 0; x < Number(gameConsts.width); x++) {
				// const blockIndex = y * width + x;
				context.fillStyle = "#ACACAC";
				context.fillRect(
					(x + PADDING_BLOCKS) * blockSize,
					(y + PADDING_BLOCKS) * blockSize,
					blockSize - GRID_WIDTH,
					blockSize - GRID_WIDTH
				);
			}
		}
	});

	const isGameOver = false;
	const connectWalletConfigObj = connectWalletConfig();
	createWeb3Modal(connectWalletConfigObj);
	const { open } = useWeb3Modal();
	const { address, chainId, isConnected } = useWeb3ModalAccount();
	const { walletProvider } = useWeb3ModalProvider();

	const [blockSelectedByCanvasClick, setBlockSelectedByCanvasClick] =
		useRecoilState(blockSelectedByCanvasClickState);
	const [blockSelectedLoaded, setBlockSelectedLoaded] = useRecoilState(
		blockSelectedLoadedState
	);
	const [myBlockSelected, setMyBlockSelected] =
		useRecoilState(myBlockSelectedState);
	const myBlockSelectedChagedHandler = () => {};
	const [canvasRender, setCanvasRender] = useRecoilState(canvasRenderState);
	const [blockFromUrlParams, setBlockFromUrlParams] = useRecoilState(
		blockFromUrlParamsState
	);
	const yangYinBlocks = useRecoilValue(yangYinChingsState);
	// const balance = useRecoilValue(balanceState);

	const [gameConsts, setGameConsts] = useRecoilState(gameConstsState);
	const [manifestedBlockSelected, setManifestedBlockSelected] = useRecoilState(
		manifestedBlockSelectedState
	);

	const [infoTilePosition, setInfoTilePosition] = useRecoilState(
		infoTilePositionState
	);

	useEffect(() => {
		myBlockSelectedChagedHandler();
	}, [myBlockSelected]);
	useEffect(() => {
		handleChingClicked({
			x: blockSelectedByCanvasClick?.x,
			y: blockSelectedByCanvasClick?.y,
		});
	}, [blockSelectedByCanvasClick]);

	async function handleUserPurchase(attachedLink = "") {
		if (!isConnected) {
			alert("no wallet connected");
			return;
		}
		try {
			setIsLoader(true);
			const prices = await contract.methods.DAO_BASE_FEE().call();
			const priceInMatic = prices * 2n ** activeChingSelected.deCount;

			console.log("priceInMatic", priceInMatic);
			const ethersProvider = new ethers.providers.Web3Provider(walletProvider);

			const signer = ethersProvider.getSigner();
			console.log("signer", signer);

			const USDTContract = new ethers.Contract(contractAddress, abi, signer);
			const USDTBalance = await USDTContract.vote(
				activeChingSelected.id,
				playerInfo?.side === "yang",
				attachedLink,
				{
					value: priceInMatic,
				}
			)
				.then(() => {
					const newProcessing = {
						isFinished: false,
						result: null,
						deCount: Number(activeChingSelected.deCount),
						x: activeChingSelected.x,
						y: activeChingSelected.y,
						chingId: activeChingSelected.id,
						link: attachedLink,
						processingSide: playerInfo?.side,
					};
					setProcessingBlocks((oldVal) => oldVal.concat([newProcessing]));
					setBlockToPop(() => newProcessing);
					activeChingSelected.deCount < 1n
						? setIsDisplayPotentiatedPopup(() => true)
						: setIsDisplayManifestedPopup(() => true);
					setActiveChingSelected(null);
					setUiMode(modes.yourChings);
					// setBlockToPop(() => activeChingSelected);
					// setIsDisplayManifestedPopup(true);
				})
				.catch((error) => {
					switch (error?.code) {
						case "ACTION_REJECTED":
							break;
						case -32603:
							handleOpenBottomError(
								`Looks like the wallet has insufficient balance for this operation. Top up the wallet and try again!`
							);
							break;
						default:
							handleOpenBottomError(
								`Ooops! Something went wrong. Please, refresh page and try again. If it doesn't work will free to contact our support on Discord: `
							);
							console.log("so strange metamask error", error);
					}
					console.log("metamaskic4545783467582367845653876", error.code);
				})
				.finally(() => {
					setIsLoader(false);
				});
		} catch (err) {
		} finally {
		}
	}

	const [contract, setContract] = useState(getInfuraContract());
	useEffect(() => {
		if (!address) setPlayerInfo({ side: "", address: "" });
		if (!contract) return;
		const contractWS = getInfuraWSContract();

		const event = contractWS.events.Voted(
			{
				fromBlock: "latest",
			},
			function (error, event) {
				if (error) {
					console.error("event voted Error:", error);
				} else {
					console.log("event voted Event:", event);
				}
			}
		);

		console.log("event", event);
		event.on("connected", (data) => {
			fetchUpdates(address, contract);
		});
		// event.on("changed", (data) => {
		// 	console.log("kek changed", data);
		// 	setUpGame(contract);
		// });
		event.on("error", (data) => {
			console.log("kek error", data);
		});
		event.on("data", function (event) {
			console.log("kek data", event); // same results as the optional callback above
			// setUpGame(contract);
			fetchUpdates(address, contract);
		});
	}, [contract, address]);

	const [dimensions, setDimensions] = useRecoilState(dimensionsState);

	const isBalance = () =>
		getBalance(getYangYinChings(blockInfos)) <= 0.5 &&
		getBalance(getYangYinChings(blockInfos)) >= -0.5;
	const scaleBalanceValueToPercents = (originalValue) => {
		let normalized = (originalValue + 1) / 2;
		let scaled = 1 + normalized * 98;
		return scaled;
	};

	const [hoveredMyChing, setHoveredMyChing] = useState(null);

	const handleSetHoveredMyChing = (chingId) => {
		setHoveredMyChing({
			y: Math.floor(chingId / 152) + 1,
			x: (chingId % 152) + 1,
		});
	};

	const getQueryParams = () => {
		const searchParams = new URLSearchParams(window.location.search);
		return {
			id: searchParams.get("id") || null,
		};
	};
	const handleChingClicked = async ({ x, y }) => {
		// if (!address) return;
		if (
			x === null ||
			x === undefined ||
			y === null ||
			y === undefined ||
			x < 0 ||
			y < 0
		)
			return;
		const id = dimensions.width * (y - 1) + x - 1;
		if (Number(id) < 0) return;
		try {
			const chingByIndex = await contract.methods.getChing(id).call();
			if (!chingByIndex) return;
			if (!address) {
				if (chingByIndex.deCount >= 2n) {
					// const selectedBlock = {
					// 	id,
					// 	x,
					// 	y,
					// 	chingId: chingByIndex.chingId,
					// 	link: chingByIndex.link,
					// 	owner: chingByIndex.owner,
					// 	isYang: chingByIndex.isYang,
					// 	deCount: chingByIndex.deCount,
					// };
					// setManifestedBlockSelected(() => selectedBlock);
					// return;
				} else {
					return;
				}
			}
			if (!playerInfo?.side && chingByIndex.deCount < 2n) {
				// lulkek1702
				setIsChoosingSide(true);
				// setBlockClickAfterChoosingSide(() => ({ x, y }));
				return;
			}

			const selectedBlock = {
				id,
				x,
				y,
				chingId: chingByIndex.chingId,
				link: chingByIndex.link,
				owner: chingByIndex.owner,
				isYang: chingByIndex.isYang,
				deCount: chingByIndex.deCount,
			};
			setBlockSelectedLoaded(() => selectedBlock);
			setActiveChingSelected(() => selectedBlock);
			setManifestedBlockSelected(() => selectedBlock);

			let modeToSet;
			if (chingByIndex.deCount < 1n) modeToSet = modes.potentialing;
			else if (chingByIndex.deCount < 2n) modeToSet = modes.manifesting;
			else if (
				uiMode === modes.remapping &&
				Math.abs(getBalance(yangYinBlocks)) <= 0.5 &&
				chingByIndex.deCount >= 2n
			)
				modeToSet = modes.remappingPanel;
			else if (chingByIndex.deCount >= 2n)
				modeToSet = modes.manifestedBlockSelected;
			else return;

			setUiMode(() => modeToSet);
		} catch (err) {
			console.log("err", err);
		}
	};

	const setQueryParams = (newId) => {
		const isSomethingToSet = newId !== null;
		const params = new URLSearchParams();
		if (isSomethingToSet) {
			params.set("id", newId);
			window.history.pushState({}, "", `${window.location.pathname}?${params}`);
		} else {
			params.delete("id");
			window.history.pushState({}, "", `${window.location.pathname}`);
		}
	};

	// TODO: когда запрос пойдет на rpc без подключенного кошелька нужно
	// будет перефильтровать все для нахождения yourChings
	const [blockInfos, setBlockInfos] = useRecoilState(blockInfosState);
	const [playerInfo, setPlayerInfo] = useRecoilState(playerInfoState);
	const renderMode = useRecoilValue(renderModeState);

	const [uiMode, setUiMode] = useRecoilState(uiModeState);

	const [isChoosingSide, setIsChoosingSide] = useState(false);
	const [blockClickAfterChoosingSide, setBlockClickAfterChoosingSide] =
		useState(null);
	const [isDisplayDescription, setIsDisplayDescription] = useState(false);
	const displayDescriptionManualClosed = () => setIsDisplayDescription(false);
	const handleDisplayDescriptionClicked = () => setIsDisplayDescription(true);

	const newCookies = Cookies.get(`processing-blocks-${address}`);
	const [processingBlocks, setProcessingBlocks] = useState(
		newCookies ? JSON.parse(newCookies).filter((x) => !x.isFinished) : []
	);

	const [blockToPop, setBlockToPop] = useState(null);
	const [isDisplayManifestedPopup, setIsDisplayManifestedPopup] =
		useState(false);
	const [isDisplayPotentiatedPopup, setIsDisplayPotentiatedPopup] =
		useState(false);
	const handleManifestedPopupClosedClicked = () =>
		setIsDisplayManifestedPopup(() => false);

	const [panelWidth, setPanelWidth] = useState("100vw");
	const handleUpdatePanelWidth = (newVal) => {
		setPanelWidth(newVal);
	};

	const handleYourChingsClicked = () => {
		setUiMode(() => modes.yourChings);
		setCanvasRender(() => ({
			// aim: "none",
			aim: null,
			drawMode: "yours",
		}));
	};

	const displayChooseSide = () => playerInfo.address && !playerInfo.side;
	// TODO: handle click on ching before
	const chooseSide = (side) => {
		if (!isChoosingSide || !side) return;
		setPlayerInfo((oldV) => ({
			...oldV,
			side,
		}));
		// store cookies
		Cookies.set(`playerSide-${playerInfo.address}`, side);
		setIsChoosingSide(false);
		if (!!blockClickAfterChoosingSide)
			handleChingClicked(blockClickAfterChoosingSide);
	};
	const displaySideLabel = () => !!playerInfo.side;
	const [isLoader, setIsLoader] = useState(true);
	const isLoading = () => Object.keys(blockInfos).length === 0 || isLoader;

	const [activeChingSelected, setActiveChingSelected] = useState(null);
	const handleCancelBlockSelection = () => {
		setQueryParams(null);
		setBlockFromUrlParams(null);
		setCanvasRender(() => ({
			aim: null,
			drawMode: "default",
		}));

		switch (uiMode) {
			case modes.remapping:
				setBgColor("#8f8f8f");
				setUiMode(() => modes.default);
				break;
			case modes.yourChingSelected:
				setUiMode(() => modes.yourChings);
				break;
			case modes.remappingPanel:
				setUiMode(() => modes.remapping);

				break;
			default:
				setUiMode(() => modes.default);
		}
		setBlockSelectedByCanvasClick(null);
		// TODO: remove it
		setActiveChingSelected(null);
		setBlockSelectedLoaded(() => null);
		setMyBlockSelected(() => null);
		setManifestedBlockSelected(() => null);
		setInfoTilePosition({ x: null, y: null, h: null, v: null, isYang: null });
		// setCanvasRender(() => ({
		// 	aim: null,
		// 	drawMode: "default",
		// }));
	};

	const handleShowMappingClicked = () => {
		setUiMode(() => modes.mapping);
		setCanvasRender(() => ({
			aim: null,
			drawMode: "default",
		}));
	};
	const handleWatchReplayClicked = () => {
		setUiMode(() => modes.replay);
		startReplayAnimation();
	};

	const handleConnectWalletClick = async () => {
		open();
	};

	const [bottomError, setBottomError] = useState({
		textMessage: "",
		isOpen: null,
	});
	const handleOpenBottomError = (textMessage, timeoutTimer = 15000) => {
		// TODO: clear timeout
		setBottomError(() => ({
			textMessage,
			isOpen: true,
		}));

		const timer = setTimeout(() => {
			setBottomError(() => ({
				textMessage: "",
				isOpen: false,
			}));
		}, timeoutTimer);
	};
	const handleCloseBottomError = () => {
		setBottomError(() => ({
			textMessage: "",
			isOpen: false,
		}));
	};

	useEffect(() => {
		// TODO: check also if string is looks like crypto address in Polygon
		if (!address) {
			return;
		}
		const newCookies = Cookies.get(`processing-blocks-${address}`);
		setProcessingBlocks(() => (newCookies ? JSON.parse(newCookies) : []));

		setPlayerInfo((oldV) => ({
			...oldV,
			address,
		}));
	}, [address]);

	useEffect(() => {
		if (!address) {
			// setProcessingBlocks(() => []);
			return;
		}
		// startPolling();
		console.log("processingBlocks", processingBlocks);
		Cookies.set(
			`processing-blocks-${address}`,
			JSON.stringify(processingBlocks)
		);
	}, [processingBlocks, address]);

	useEffect(() => {
		try {
			if (!address) return;
			contract.methods
				.voterSides(address)
				.call()
				.then((playerSideResponse) => {
					const playerSide =
						playerSideResponse.voter.toUpperCase() === address.toUpperCase()
							? playerSideResponse.isYang
								? "yang"
								: "yin"
							: Cookies.get(`playerSide-${address}`);
					setPlayerInfo(() => ({ address: address, side: playerSide }));
					if (!playerSide) {
						setIsChoosingSide(true);
					}
				});
		} catch (error) {
			console.error("User denied wallet access");
		}
	}, [address]);

	const fetchUpdates = async (address, contract) => {
		console.log("FETCH UPDATES START WORKING...");
		if (!contract) return;
		// setDimensions({ width: Number(width), height: Number(height) });

		const blockInfosArray = await contract.methods.getChings().call();
		const blockInfosMap = blockInfosArray.reduce((map, pair) => {
			const blockIndex = pair[0];
			const info = pair[1];
			map[blockIndex] = { ...info, chingId: Number(blockIndex) };
			return map;
		}, {});
		setBlockInfos(blockInfosMap);
		// alert(`inside${address}`);
		if (!address) return;
		const cookies = Cookies.get(`processing-blocks-${address}`);
		const kek = cookies ? JSON.parse(cookies) : [];
		// console.log("OLD processingBlocks", kek);
		const checkBlockUpdates = kek?.filter((x) => !x.isFinished);
		console.log("checkBlockUpdates", checkBlockUpdates);
		const newValues = checkBlockUpdates.map((x) => blockInfosMap[x.chingId]);
		console.log("newValues", newValues);

		const updatedBlocks = [];
		newValues.forEach((newV) => {
			// still primordial
			if (!newV) {
				return;
			}
			const processingObject = checkBlockUpdates.find(
				(x) => Number(x.chingId) === Number(newV.chingId)
			);
			console.log("processingObject", processingObject);
			if (!processingObject) return; // this is strange error could be
			// still same votes amount
			if (Number(processingObject.deCount) === Number(newV.deCount)) {
				updatedBlocks.push(processingObject);
			}
			// successfully voted
			if (
				Number(processingObject.deCount) + 1 === Number(newV.deCount) &&
				newV.owner.toUpperCase() === address.toUpperCase()
			) {
				updatedBlocks.push({
					...processingObject,
					isFinished: true,
					result: "success",
				});
			}
			// double spending
			if (
				Number(processingObject.deCount) + 1 === Number(newV.deCount) &&
				newV.owner.toUpperCase() !== address.toUpperCase()
			) {
				updatedBlocks.push({
					...processingObject,
					isFinished: true,
					result: "error",
				});
			}
		});
		console.log("updatedBlocks", updatedBlocks);

		setProcessingBlocks((oldV) => {
			const idsToSet = updatedBlocks.map((x) => x.chingId);
			const notChangedBlocks = oldV.filter(
				(x) => !idsToSet.includes(x.chingId)
			);
			return notChangedBlocks.concat(updatedBlocks);
		});
		setBlockToPop((oldV) => {
			const newBlock = updatedBlocks.find(
				(x) => Number(x.chingId) === Number(oldV?.chingId)
			);
			if (!newBlock) {
				return oldV;
			}
			return newBlock;
		});

		return { updatedBlocks };
	};

	useEffect(() => {
		fetchUpdates(address, contract);
		// setUpGame(contract);
	}, [contract, address]);

	useEffect(() => {
		try {
			contract.methods
				.DAO_BASE_FEE()
				.call()
				.then((baseFee) =>
					setGameConsts((oldV) => ({
						...oldV,
						baseFee,
					}))
				);
			contract.methods
				.WIDTH()
				.call()
				.then((width) =>
					setGameConsts((oldV) => ({
						...oldV,
						width,
					}))
				);
			contract.methods
				.HEIGHT()
				.call()
				.then((height) =>
					setGameConsts((oldV) => ({
						...oldV,
						height,
					}))
				);
		} catch (error) {
		} finally {
			setIsLoader(false);
		}
	}, []);

	const { id } = getQueryParams();
	const blockFromParams = blockInfos[id];
	if (blockFromParams && uiMode !== modes.paramBlock) {
		setBlockFromUrlParams(() => blockInfos[id]);
		setUiMode(() => modes.paramBlock);
		setCanvasRender((oldV) => ({
			hoverAim: oldV?.hoverAim,
			aim: convertIdToXY(id),
			drawMode: "default",
		}));
	}

	const [bgColor, setBgColor] = useState("#8f8f8f");

	const startForwardAnimation = () => {
		setBgColor("#f67052");
	};

	const [drawingSpeed, setDrawingSpeed] = useState(1000);
	const [isDrawing, setIsDrawing] = useState(false);
	const [currentBlockIndex, setCurrentBlockIndex] = useState(0);
	const chingsVotedRef = useRef([]);
	const timeoutRef = useRef(null);

	const drawNextBlock = useCallback(() => {
		const chingsVoted = chingsVotedRef.current;

		if (currentBlockIndex < chingsVoted.length && isDrawing) {
			const block = chingsVoted[currentBlockIndex];
			const context = canvasRef.current.getContext("2d");
			context.fillStyle = "white";
			const { x, y, isYang } = {
				x: Number(block?.chingId) % Number(gameConsts.width),
				y: Math.floor(Number(block?.chingId) / Number(gameConsts.width)),
				isYang: block?.isYang,
			};
			drawBlock(context, x, y, isYang, block.deCount);

			// Schedule next block with the current drawing speed
			timeoutRef.current = setTimeout(() => {
				setCurrentBlockIndex((index) => index + 1);
			}, drawingSpeed);
		}
	}, [currentBlockIndex, drawingSpeed, isDrawing]);

	useEffect(() => {
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current); // Clear any existing timeout
		}

		drawNextBlock();

		return () => {
			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current);
			}
		};
	}, [drawingSpeed, drawNextBlock]);

	const startReplayAnimation = async () => {
		// if (timeoutRef.current) {
		// 	clearTimeout(timeoutRef.current); // Clear any ongoing animation
		// }

		const chingsVoted = await contract.methods.getChingsVoted().call();
		chingsVotedRef.current = chingsVoted;
		setIsDrawing(true);
		if (!timeoutRef.current) {
			drawNextBlock(0); // Start from the beginning
		}
	};

	const stopAnimation = () => {
		setIsDrawing(false);
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
			timeoutRef.current = null;
		}
	};
	return (
		<div>
			<section id="top" className="ddc-game" style={{ "--bg-color": bgColor }}>
				{isLoading() || !contract ? (
					<div className="loader">
						<img
							src={loaderImg}
							loading="lazy"
							alt=""
							className="rotating-image"
						/>
					</div>
				) : (
					<div className="container">
						<MobileDescription
							yangAmount={
								Object.keys(getYangYinChings(blockInfos)?.yang).length
							}
							yinAmount={Object.keys(getYangYinChings(blockInfos)?.yin).length}
						/>
						<DescriptionPopup
							isHide={!isDisplayDescription}
							manualClosed={displayDescriptionManualClosed}
							panelWidth={panelWidth}
						/>
						<ManifestedBlockPopup
							isHide={!isDisplayManifestedPopup}
							block={blockToPop}
							manualClosed={handleManifestedPopupClosedClicked}
							playerSide={playerInfo?.side}
						/>
						<PotentiatedBlockPopup
							isHide={!isDisplayPotentiatedPopup}
							block={blockToPop}
							manualClosed={() => setIsDisplayPotentiatedPopup(() => false)}
							playerSide={playerInfo?.side}
						/>
						<ChooseSidePopup
							isHide={!isChoosingSide}
							manualClosed={() => setIsChoosingSide(false)}
							sideSelected={chooseSide}
						/>
						{
							<span
								className={
									"logo white-text padding-top" +
									handleHideClass(!(uiMode === modes.default))
								}
								style={{
									width: panelWidth,
								}}
							>
								DAO DE CHING
							</span>
						}

						<div
							id="ddc-description-top-container"
							className={"description-wrapper mob-hide"}
							style={{
								width: panelWidth,
							}}
						>
							{
								<span
									className={
										"logo white-text padding-top-mini" +
										handleHideClass(
											![modes.remapping, modes.replay].includes(uiMode)
										)
									}
									style={{
										width: panelWidth,
									}}
								>
									DAO DE CHING
								</span>
							}
							{uiMode !== modes.default && uiMode !== modes.replay && (
								<div className="close">
									<button
										onClick={handleCancelBlockSelection}
										className="button"
									>
										X
									</button>
								</div>
							)}
							<ManifestBlock
								isHide={uiMode !== modes.manifesting}
								block={activeChingSelected}
								playerSide={playerInfo?.side}
								manifestBlock={handleUserPurchase}
							/>
							<PotentiateBlock
								block={activeChingSelected}
								playerSide={playerInfo?.side}
								isHide={uiMode !== modes.potentialing}
								potentiateBlock={handleUserPurchase}
							/>
							<Mapping isHide={uiMode !== modes.mapping} />

							<Remapping />
							<RemappingPanel manifestBlock={handleUserPurchase} />
							<Manymanymanyblocksowned
								isHide={uiMode !== modes.yourChings}
								processingBlocks={processingBlocks?.filter(
									(x) => !x.isFinished
								)}
								chings={Object.keys(blockInfos)?.reduce((newObj, key) => {
									if (
										blockInfos[key].owner.toUpperCase() ===
										playerInfo.address.toUpperCase()
									) {
										newObj[key] = blockInfos[key];
									}
									return newObj;
								}, {})}
								setHoveredMyChing={handleSetHoveredMyChing}
							/>

							<YourChingSelected />
							<BlockFromUrl />
							<ManifestedBlockSelected />

							<Replay
								changeSpeed={(speed) => setDrawingSpeed(speed)}
								speed={drawingSpeed}
								isDrawing={isDrawing}
								stop={stopAnimation}
								start={startReplayAnimation}
							/>

							{/* default ui mode */}
							{uiMode === modes.default && (
								<div
									className={
										uiMode === modes.default
											? "top-panel visible"
											: "top-panel hidden"
									}
								>
									<div className="div-2-3">
										{!isGameOver ? (
											<p
												className={
													"paragraph extra-small macfix mini-description" +
													handleHideClass(uiMode !== modes.default)
												}
											>
												Choose your force, either YANG or YIN. Choose your
												CHING. First pass is potential. Second pass manifests
												CHING with link attribute. Significant imbalance will
												allow players to remap enemy's manifested CHING. Game is
												over when all the CHINGS are manifested.
											</p>
										) : (
											<>
												<p className={"paragraph extra-small replay-label"}>
													GAME OVER
												</p>
												<p
													className={
														"paragraph extra-small macfix mini-description" +
														handleHideClass(uiMode !== modes.default)
													}
												>
													<span className="white-text">
														{Object.keys(yangYinBlocks?.yang).length} YANG
													</span>{" "}
													and{" "}
													<span className="white-text">
														{Object.keys(yangYinBlocks?.yin).length} YIN
													</span>{" "}
													CHINGS were manifested.
													<br />
													In total:{" "}
													<span className="white-text">
														{
															Object.keys(blockInfos).reduce(
																(ownersArr, key) => {
																	return blockInfos[key].owner &&
																		!ownersArr.includes(blockInfos[key].owner)
																		? ownersArr.concat([blockInfos[key].owner])
																		: ownersArr;
																},
																[]
															).length
														}{" "}
														players
													</span>{" "}
													participated in game.
												</p>
											</>
										)}
									</div>
									<div className={"div-1-3 align-right"}>
										<p
											className={
												"paragraph extra-small margin-bottom" +
												handleHideClass(!address)
											}
										>
											{removeChars(address, 6, 34)}
											{playerInfo?.side && (
												<>
													|{" "}
													<span
														className={`side-sq-${
															playerInfo?.side === "yang" ? "yang" : "yin"
														}`}
													></span>{" "}
													{playerInfo?.side?.toUpperCase()}
												</>
											)}
											<br />
										</p>
										<button
											className={`button${address ? " hide" : ""}`}
											onClick={handleConnectWalletClick}
										>
											{playerInfo.address || uiMode !== modes.default
												? "open connect wallet"
												: "connect wallet ..."}
										</button>

										<button
											onClick={() => setIsChoosingSide(true)}
											className={
												"button blue" + handleHideClass(!displayChooseSide())
											}
										>
											choose your force
										</button>
										<button
											onClick={handleYourChingsClicked}
											className={
												"button" +
												(!displaySideLabel() ||
												Object.values(blockInfos).filter(
													(ching) =>
														ching.owner.toUpperCase() ===
														playerInfo.address.toUpperCase()
												).length < 1
													? " hide"
													: "")
											}
										>
											your CHINGS
										</button>
									</div>

									<div
										className={
											"column-3 between" +
											handleHideClass(uiMode !== modes.default)
										}
									>
										<button
											onClick={handleDisplayDescriptionClicked}
											id="w-node-_3564e758-06ff-45c0-7369-6ef5d43a8bef-54442f70"
											className="button nomargin"
										>
											full description
										</button>
										{/* formula indicator */}
										{/* |||{processingBlocks.length}|||
									{isDisplayManifestedPopup.toString()} */}
										{!isGameOver && (
											<div
												id="w-node-_3564e758-06ff-45c0-7369-6ef5d43a8bf1-54442f70"
												className="polygon w-embed"
												style={{
													left: `${scaleBalanceValueToPercents(
														getBalance(getYangYinChings(blockInfos))
													)}%`,
												}}
											>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="12"
													height="9"
													viewBox="0 0 12 9"
													fill="none"
												>
													<path
														d="M6 9L0.803849 -9.78799e-07L11.1962 -7.02746e-08L6 9Z"
														fill={isBalance() ? "#4EFF3F" : "#F67052"}
													></path>
												</svg>
											</div>
										)}
										{/* formula */}
										{!isGameOver && (
											<div
												id="w-node-_3564e758-06ff-45c0-7369-6ef5d43a8bf2-54442f70"
												className="yinyangbalance"
											>
												<div className="yinyang-wrapper">
													<div className="yang-line">
														<div className="vline white"></div>
														<div className="vlinemini white"></div>
													</div>
													<div className="yin-line">
														<div className="vline attach"></div>
														<div className="vlinemini"></div>
													</div>
												</div>
												<div className="balancetext-wrapper">
													<p className="label-text white-text">
														{
															Object.keys(getYangYinChings(blockInfos)?.yang)
																.length
														}{" "}
														yang
														<br />
													</p>
													<p className="label-text">
														DAO{" "}
														{isBalance()
															? "in dynamic balance"
															: "imbalanced: remapping unlocked"}
														<br />
													</p>
													<p className="label-text">
														{
															Object.keys(getYangYinChings(blockInfos)?.yin)
																.length
														}{" "}
														yin
														<br />
													</p>
												</div>
											</div>
										)}
										{playerInfo?.side && !isBalance() && (
											<button
												id="w-node-_3564e758-06ff-45c0-7369-6ef5d43a8c04-54442f70"
												className={`button orange remap-${playerInfo.side}`}
												onClick={() => {
													setUiMode(() => modes.remapping);
													startForwardAnimation();
												}}
											>
												remap enemy
											</button>
										)}
										{!isGameOver ? (
											<button
												id="w-node-_3564e758-06ff-45c0-7369-6ef5d43a8c06-54442f70"
												className="button nomargin"
												onClick={handleShowMappingClicked}
											>
												mapping
											</button>
										) : (
											<button
												id="w-node-_3564e758-06ff-45c0-7369-6ef5d43a8c06-54442f70"
												className="button nomargin blue"
												onClick={handleWatchReplayClicked}
											>
												watch replay
											</button>
										)}
									</div>
								</div>
							)}
						</div>
						{uiMode !== modes.replay && (
							<RenderEngine
								isGameOver={isGameOver}
								width={dimensions.width}
								height={dimensions.height}
								renderMode={renderMode}
								blockInfos={blockInfos}
								onChingClicked={handleChingClicked}
								myHoveredChing={hoveredMyChing}
								updatePanelWidth={handleUpdatePanelWidth}
							/>
						)}
						{/* TODO: change isGameOver to uiMode === modes.replay */}
						{isGameOver && uiMode === modes.replay && (
							<div className="canvas-wrapper-internal">
								<canvas ref={canvasRef} />
							</div>
						)}
						{/* {
							<ReplayCanvas
								blockInfos={blockInfos}
								style={{
									display: uiMode === modes.replay ? "flex" : "none",
								}}
								ref={replayCanvasRef}
								updatePanelWidth={handleUpdatePanelWidth}
							/>
						} */}
						{/* errors */}
						{bottomError?.isOpen !== null && (
							<div
								className={
									"error-bottom " +
									(bottomError?.isOpen ? "showFromBottom" : "hideToBottom")
								}
							>
								<div
									className="error-wrapp"
									style={{
										width: panelWidth,
									}}
								>
									<p className="error-message">{bottomError?.textMessage}</p>
									<button
										className="error-close"
										onClick={handleCloseBottomError}
									>
										X
									</button>
								</div>
							</div>
						)}
						<GameFooter
							yangYinChings={getYangYinChings(blockInfos)}
							panelWidth={panelWidth}
						/>
						<InfoCard />
					</div>
				)}
			</section>
		</div>
	);
}

export default App;
