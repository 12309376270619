import { atom } from 'recoil';

// export const modes = {
//   activeChingSelected: "potentialing",
//   mapping: "mapping",
//   default: "default",
//   yourChings: "yourChings",
//   manifesting: "manifesting",
// };

export const canvasRenderState = atom({
  key: 'canvasRenderState',
  default: null,
});
