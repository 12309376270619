import { useRecoilValue } from "recoil";

import yinyangsvg from "../Yin-Yang.svg";
import { yourChingsState } from "../state/your-chings.state";
import { balanceState } from "../state/balance.state";

function MobileDescription({ yangAmount, yinAmount }) {
	const yangYinBlocks = useRecoilValue(yourChingsState);
	const balance = useRecoilValue(balanceState);

	return (
		<div className="description-wrapper mob-show">
			<div className="together-div vertical">
				<p className="heading white-text">
					DAO DE CHING
					<br />
				</p>
				<img src={yinyangsvg} loading="lazy" alt="" className="img-medium" />
				<p className="paragraph small white-text">
					This game designed for larger screens of desktop or laptop computers
					with a mouse or a trackpad.
					<br />
				</p>
			</div>
			<div className="together-div">
				<div className="ill-text-line margin-bottom">
					<div className="status-ellipse"></div>
					<p className="paragraph meta">
						DAO is{" "}
						{Math.abs(balance) <= 0.5
							? "balanced"
							: "imbalanced: remapping unlocked"}
						: <span className="white-text">{yangAmount} YANG</span> |{" "}
						{yinAmount} YIN
						<br />
					</p>
				</div>
				<p className="paragraph meta">
					<span className="white-text">
						{(10640 - yangAmount - yinAmount).toString()} CHINGS{" "}
					</span>
					to be manifested until the game over
					<br />
				</p>
			</div>
			<p className="paragraph meta">
				Twitter (X):&nbsp;
				<a
					href="https://twitter.com/daodechinggame"
					target="_blank"
					rel="noopener noreferrer"
					className="white-text"
				>
					@daodechinggame
				</a>
			</p>
		</div>
	);
}

export default MobileDescription;
