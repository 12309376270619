import { useRecoilValue, useRecoilState } from "recoil";

import { uiModeState, modes } from "../state/uiMode.state";
import { yangYinChingsState } from "../state/yang-yin-chings.state";
import { balanceState } from "../state/balance.state";

function Remapping() {
	const [uiMode, setUiMode] = useRecoilState(uiModeState);
	const isHide = uiMode !== modes.remapping;
	const yangYinBlocks = useRecoilValue(yangYinChingsState);
	const balance = useRecoilValue(balanceState);

	const scaleBalanceValueToPercents = (originalValue) => {
		let normalized = (originalValue + 1) / 2;
		let scaled = 1 + normalized * 98;
		return scaled;
	};

	const popupClass = isHide ? "top-panel hidden" : "top-panel visible";
	const contentClass = isHide ? "fade-out" : "fade-in";
	// fill={isBalance() ? "#4EFF3F" : "#F67052"}
	return (
		<div className={popupClass}>
			<div className="div-2-3">
				<p className={"paragraph extra-small remap-label"}>Remap mode</p>
			</div>
			<div className={"div-1-3 align-right"}></div>

			<div className={"column-3 between"}>
				{/* formula indicator */}
				{/* |||{processingBlocks.length}|||
									{isDisplayManifestedPopup.toString()} */}
				<div
					id="w-node-_3564e758-06ff-45c0-7369-6ef5d43a8bf1-54442f70"
					className="polygon-remap w-embed"
					style={{
						left: `${scaleBalanceValueToPercents(balance)}%`,
					}}
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="12"
						height="9"
						viewBox="0 0 12 9"
						fill="none"
					>
						<path
							d="M6 9L0.803849 -9.78799e-07L11.1962 -7.02746e-08L6 9Z"
							fill="black"
						></path>
					</svg>
				</div>
				{/* formula */}
				<div
					id="w-node-_3564e758-06ff-45c0-7369-6ef5d43a8bf2-54442f70"
					className="yinyangbalance"
				>
					<div className="yinyang-wrapper">
						<div className="yang-line">
							<div className="vline white"></div>
							<div className="vlinemini white"></div>
						</div>
						<div className="yin-line">
							<div className="vline attach"></div>
							<div className="vlinemini"></div>
						</div>
					</div>
					<div className="balancetext-wrapper">
						<p className="label-text white-text">
							{Object.keys(yangYinBlocks?.yang).length} yang <br />
						</p>
						<p className="label-text">
							DAO{" "}
							{Math.abs(balance) <= 0.5
								? "in dynamic balance"
								: "imbalanced: remapping unlocked"}
							<br />
						</p>
						<p className="label-text">
							{Object.keys(yangYinBlocks?.yin).length} yin <br />
							<br />
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Remapping;
