import { useRecoilValue } from "recoil";
import { useState } from "react";

import formulaImg from "../Formula.svg";
import { playerInfoState } from "../state/player-info.state";

function DescriptionPopup({ isHide, manualClosed, panelWidth }) {
	// const [selectedSide, setSelectedSide] = useState(null);
	const discordLogin = () => {
		const clientId = "1201550665030312139";
		const redirectUri = encodeURIComponent(
			`https://api.daodechinggame.com/registerDiscordUser`
		);
		const scope = encodeURIComponent("identify"); // 'identify' scope is used to fetch user's basic information
		const state = {
			wallet: playerInfo?.address,
		};
		const jsonString = JSON.stringify(state);
		const encodedState = encodeURIComponent(
			Buffer.from(jsonString).toString("base64")
		);
		const discordUrl = `https://discord.com/api/oauth2/authorize?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}&scope=${scope}&state=${encodedState}`;
		// window.location.href = discordUrl;
		// setShowConnectButton(false);
		window.open(discordUrl, "_blank"); // Opens the URL in a new tab
	};
	const handleCloseClick = () => manualClosed();
	const playerInfo = useRecoilValue(playerInfoState);
	const popupClass = isHide ? "pop hidden" : "pop visible";
	const contentClass = isHide
		? "popup-content fade-out"
		: "popup-content fade-in";

	const [showConnectButton, setShowConnectButton] = useState(true);
	return (
		<div className={`full-desc-wrapper ${popupClass}`}>
			<div
				className={`header-desc ${contentClass}`}
				style={{
					width: panelWidth,
				}}
			>
				<div className="column-2">
					<p className="paragraph extra-small">
						<br />
					</p>
					<button
						id="w-node-_3564e758-06ff-45c0-7369-6ef5d43a8976-54442f70"
						className="button"
						onClick={handleCloseClick}
					>
						X
					</button>
				</div>
			</div>
			<div
				className={`scroll-wrapper ${contentClass}`}
				style={{
					width: panelWidth,
				}}
			>
				<div className="column-2 _1-bigger">
					<p
						id="w-node-_3564e758-06ff-45c0-7369-6ef5d43a897a-54442f70"
						className="paragraph extra-small"
					>
						<span className="white-text">LEGEND</span>
						<br />‍<br />
						Once, in the realm of the formless and infinite, there existed a
						vast and boundless void, a place of perfect peace and stillness,
						where nothing existed, yet everything was possible. In this serene
						expanse, there lay the seed of all creation, the essence of all
						potential: <span className="white-text">DAO</span>. As the eternal
						dance of the universe began, the DAO stirred, and from this profound
						stillness emerged the plane that contained the delicate balance of
						all that was to come. This plane, glowing with the light of nascent
						possibilities, split into two complementary forces:{" "}
						<span className="white-text">YIN</span> and{" "}
						<span className="white-text">YANG</span>.<br />‍<br />
						YIN was soft and yielding, like the gentle caress of moonlight. She
						flowed like water, cool and mysterious, carrying within her the
						depth of the night sky. YANG, on the other hand, was bold
						and vigorous, his energy radiant like the sun. He blazed with heat
						and light, his strength as unwavering as the mountains. Together,
						YIN and YANG danced in an eternal embrace, their movements giving
						birth to all the pieces of matter,
						<span className="white-text"> CHINGS</span>, in the universe in two
						steps:
						<br />‍<br />
						<span className="white-text">The first step</span> gave the CHING
						its potential. <br />
						<span className="white-text">In the second step</span>, the CHING
						was manifested with its inherent and permanent attribute (
						<span className="white-text">LINK</span>).
						<br />
						<br />
						And thus DAO proceeded on its course to completion, until all the
						CHINGS were manifested with their attributes.
						<br />
						<br />
						However,
						<span className="white-text"> balance was crucial</span> in that
						process, and each time either of the forces overpowered their
						counterpart, the DAO became imbalanced, unlocking the ability for
						either force to reinstate the manifested enemy CHINGS with their own
						attributes. That ability was unlocked either until the balance was
						restored or all the CHINGS were manifested —the end of DAO&#x27;s
						game.
						<br />
					</p>
					<p
						id="w-node-_3564e758-06ff-45c0-7369-6ef5d43a89a9-54442f70"
						className="paragraph extra-small"
					>
						<span className="white-text">GAMEPLAY</span>
						<br />‍<br />
						1. Connect your wallet.
						<br />
						2. Choose YANG or YIN force.Pick a CHING (block). <br />
						3. Define your strategy: either earn 2x from converting primordial
						CHINGS and letting others manifest your them by attaching their
						links to them, or use the game to promote your stuff, manifesting
						potential CHINGS, and assigning your links to them.
						<br />
						4. Maintain the game&#x27;s balance by inviting your friends to
						represent your force type and teaming up with them to prevent the
						game&#x27;s imbalance, which will unlock for other players who
						represent the opposite force an ability to remap your manifested
						blocks and their attributes (links).
						<br />
						5. On the other hand, the game imbalance is a possibility to
						capitalize on chaos since the player who will remap your manifested
						block will pay you back almost 2x of the price you paid for its
						manifestation. You lose your link, though.
						<br />
						6. The game is over when all the blocks are manifested. After that,
						the links in the blocks remain forever.
						<br />
					</p>
					<p
						id="w-node-_3564e758-06ff-45c0-7369-6ef5d43a89bc-54442f70"
						className="paragraph extra-small"
					>
						<span className="white-text">BALANCE FORMULA</span> <br />
						<img alt="formula-svg" src={formulaImg} className="formula-img" />
						<br />
						{/* <span className="balance-index">
							<br />
							<br />
							balance index = tanh(
						</span>
						<span className="balance-index white-text">K</span>
						<span className="balance-index"> x (</span>
						<span className="balance-index white-text">
							M <sub>yin</sub>
						</span>
						<span className="balance-index"> – </span>
						<span className="balance-index white-text">
							M <sub>yang</sub>
						</span>
						<span className="balance-index">) / </span>
						<span className="balance-index white-text">
							M <sub>total</sub>
						</span>
						<span className="balance-index">
							)<br />
							<br />
						</span> */}
						M<sub>yin</sub> — amount of YIN manifested chings
						<br />
						<span className="white-text">
							M<sub>yang</sub>
						</span>{" "}
						— amount of YANG manifested chings
						<br />M<sub>total</sub> — amount of all chings(10640)
						<br />k — multiplier = 32
						<br />
						<br />
						imbalance threshold — balance index &gt; |0.5|
						<br />
					</p>
					<p
						id="w-node-_3564e758-06ff-45c0-7369-6ef5d43a89e8-54442f70"
						className="paragraph extra-small"
					>
						<br />
						Contract on{" "}
						<a
							href="https://polygonscan.com/address/0x4a6b83a1ba24d1cea649924b3190c03f255cc30a"
							target="_blank"
							rel="noopener noreferrer"
						>
							<span className="link white-text">polygon scan</span>
						</a>
						<br />
						Created by{" "}
						<a
							href="https://pongdao.org/"
							target="_blank"
							rel="noopener noreferrer"
						>
							<span className="link white-text">PONGDAO</span>
						</a>
						<br />
						<br />
						<br />
						<br />
					</p>
					<div
						id="w-node-_3564e758-06ff-45c0-7369-6ef5d43a89ff-54442f70"
						className="hr vertical"
					></div>
				</div>
			</div>
			<div
				className={"footer-desc"}
				style={{
					width: panelWidth,
				}}
			>
				<button onClick={handleCloseClick} className="button">
					play
				</button>

				<div>
					{showConnectButton && playerInfo?.address && (
						<button onClick={discordLogin} className="button margin-right">
							connect Discord
						</button>
					)}
					<a
						href="https://discord.com/channels/1187393757792981083/1187393757792981085"
						target="_blank"
						rel="noopener noreferrer"
						className="button margin-right"
					>
						join Discord
					</a>
					<a
						className="button"
						href="https://twitter.com/daodechinggame"
						target="_blank"
						rel="noopener noreferrer"
					>
						follow on X
					</a>
				</div>
			</div>
		</div>
	);
}

export default DescriptionPopup;
