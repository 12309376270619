import { atom, selector } from "recoil";
import { blockInfosState } from "./blockInfos.state";

export const yangYinChingsState = selector({
	key: "yangYinChingsState",
	get: ({ get }) => {
		const blockInfos = get(blockInfosState);
		const yangChings = Object.keys(blockInfos)?.reduce((newObj, key) => {
			if (
				blockInfos[key].owner &&
				blockInfos[key].isYang &&
				blockInfos[key].hasLink
			) {
				newObj[key] = blockInfos[key];
			}
			return newObj;
		}, {});

		const yinChings = Object.keys(blockInfos)?.reduce((newObj, key) => {
			if (
				blockInfos[key].owner &&
				!blockInfos[key].isYang &&
				blockInfos[key].hasLink
			) {
				newObj[key] = blockInfos[key];
			}
			return newObj;
		}, {});

		return { yang: yangChings, yin: yinChings };
	},
});
