import App from "./App";
import { RecoilRoot } from "recoil";
import ErrorBoundary from "./common/ErrorBoundary";

function Main() {
	return (
		<RecoilRoot>
			<App />
		</RecoilRoot>
	);
}

export default Main;
