import { useEffect, useRef, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import { RenderMode } from "./RenderMode";
import convertIdToXY from "./common/convert-id-to-XY";
import { uiModeState, modes } from "./state/uiMode.state";
import { hoverBlockState } from "./state/hoverBlock.state";
import { yourChingsState } from "./state/your-chings.state";
import { playerInfoState } from "./state/player-info.state";
import { canvasRenderState } from "./state/canvas-render-mode.state";
import { myBlockSelectedState } from "./state/my-block-selected.state";
import { infoTilePositionState } from "./state/info-tile-position.state";
import { myBlockHoveredIdState } from "./state/my-block-hovered-id.state";
import { blockFromUrlParamsState } from "./state/block-from-url-params.state";
import { blockSelectedByCanvasClickState } from "./state/block-selected-by-canvas-click.state";

export { RenderMode };

const GRID_WIDTH = 0;
const OUTLINE_WIDTH = 1;
const SCOPE_GAP = 4;
const PADDING_BLOCKS = 4;
// TODO: resize on resizing canvas
function RenderEngine({
	isGameOver,
	width,
	height,
	renderMode,
	blockInfos,
	onChingClicked,
	isLoading,
	myHoveredChing,
	updatePanelWidth,
}) {
	const canvasRef = useRef();
	const replayCanvas = useRef();
	const [blockSelectedByCanvasClick, setBlockSelectedByCanvasClick] =
		useRecoilState(blockSelectedByCanvasClickState);

	// hovering during user scrolls the canvas
	// this value is used to aim if cursor is in canvas
	// &&canvasRender.aim is empty
	const [hoveredByMouseBlock, setHoveredByMouseBlock] =
		useRecoilState(hoverBlockState);
	const [myBlockHoveredId, setMyBlockHoveredId] = useRecoilState(
		myBlockHoveredIdState
	);
	const [myBlockSelected, setMyBlockSelected] =
		useRecoilState(myBlockSelectedState);
	const [canvasRender, setCanvasRender] = useRecoilState(canvasRenderState);
	const [infoTilePosition, setInfoTilePosition] = useRecoilState(
		infoTilePositionState
	);
	const [blockFromUrlParams, setBlockFromUrlParams] = useRecoilState(
		blockFromUrlParamsState
	);
	const [playerInfo, setPlayerInfo] = useRecoilState(playerInfoState);
	const [blockSize, setBlockSize] = useState(-1);
	const uiMode = useRecoilValue(uiModeState);
	const yourChings = useRecoilValue(yourChingsState);

	const calculateBlockCoordinates = (event) => {
		const rect = canvasRef.current.getBoundingClientRect();
		const x = event.clientX - rect.left;
		const y = event.clientY - rect.top;
		const blockX = Math.floor(x / blockSize);
		const blockY = Math.floor(y / blockSize);
		return { x: blockX - PADDING_BLOCKS, y: blockY - PADDING_BLOCKS };
	};

	const handleMouseLeave = (event) => {
		const { left, top, width, height } = event.target.getBoundingClientRect();
		const x = event.clientX - left;
		const y = event.clientY - top;
		if ((x >= 0 && x < width) || (y >= 0 && y < height)) return;
		setHoveredByMouseBlock(() => null);
		setInfoTilePosition({ x: null, y: null, h: null, v: null, isYang: null });
	};

	const handleMouseMove = (event) => {
		const { x, y } = calculateBlockCoordinates(event);
		if (x < 0 || x >= width || y < 0 || y >= height) {
			setHoveredByMouseBlock(() => null);
			setInfoTilePosition({ x: null, y: null, h: null, v: null, isYang: null });
			return;
		}
		const id = 152 * y + x;
		const block = blockInfos[id];
		if (block && block.deCount && block.deCount > 1) {
			setInfoTilePosition({
				x: event.clientX,
				y: event.clientY,
				h: x,
				v: y,
				isYang: block.isYang,
				side: block.isYang ? "yang" : "ying",
				link: block.link,
			});
		} else {
			setInfoTilePosition({
				x: null,
				y: null,
				h: null,
				v: null,
				isYang: null,
			});
		}
		if (!!blockSelectedByCanvasClick) return;
		if (uiMode === modes.yourChings && !yourChings[id]) {
			setHoveredByMouseBlock(() => null);
			setInfoTilePosition({ x: null, y: null, h: null, v: null, isYang: null });
			return;
		} else if (
			uiMode === modes.remapping &&
			(!blockInfos[id] || !isRemappingAvailableBlock(blockInfos[id]))
		) {
			setHoveredByMouseBlock(() => null);
			setInfoTilePosition({ x: null, y: null, h: null, v: null, isYang: null });
			return;
		}
		if (
			!hoveredByMouseBlock ||
			hoveredByMouseBlock.x !== x ||
			hoveredByMouseBlock.y !== y
		) {
			setHoveredByMouseBlock(() => ({ x, y }));
		}
	};

	const handleMouseClick = (event) => {
		if (!!myBlockSelected || !!blockFromUrlParams) return;
		const { x, y } = calculateBlockCoordinates(event);
		if (x < 0 || x >= width || y < 0 || y >= height) {
			setHoveredByMouseBlock(() => null);
			setInfoTilePosition({ x: null, y: null, h: null, v: null, isYang: null });
			return;
		}
		const id = 152 * y + x;

		if (uiMode === modes.yourChings) {
			const yourChing = yourChings[id];
			if (!yourChing) return;
		} else if (uiMode === modes.remapping) {
			const block = blockInfos[id];
			console.log("handleMouseClick return");
			if (!block || !isRemappingAvailableBlock(block)) return;
		}

		if (!playerInfo?.address && (!blockInfos[id] || !blockInfos[id]?.hasLink))
			return;

		setCanvasRender((oldV) => ({
			...oldV,
			aim: { x: Number(x), y: Number(y) },
		}));

		setBlockSelectedByCanvasClick(() => ({
			x: Number(x) + 1,
			y: Number(y) + 1,
		}));
		// onChingClicked({ x: Number(x) + 1, y: Number(y) + 1 });
	};

	const drawBlock = (context, x, y, isYang, deCount, isTinted) => {
		if (blockSize <= 0) return;
		if (deCount === 0) {
			context.fillStyle = "#ACACAC";
			context.fillRect(
				(x + PADDING_BLOCKS) * blockSize,
				(y + PADDING_BLOCKS) * blockSize,
				blockSize - GRID_WIDTH,
				blockSize - GRID_WIDTH
			);
			return;
		}
		if (deCount === 1n) {
			context.fillStyle = isYang ? "#FFFFFF" : "#000000";
			context.fillRect(
				(x + PADDING_BLOCKS) * blockSize,
				(y + PADDING_BLOCKS) * blockSize,
				blockSize - GRID_WIDTH,
				blockSize - GRID_WIDTH
			);

			context.beginPath();
			context.fillStyle = isYang ? "#000000" : "#FFFFFF";

			let centerX = (x + PADDING_BLOCKS) * blockSize + blockSize / 2;
			let centerY = (y + PADDING_BLOCKS) * blockSize + blockSize / 2;
			let radius = (blockSize * 5) / 18;

			context.arc(centerX, centerY, radius, 0, 2 * Math.PI);
			context.fill();
		} else if (deCount > 1n) {
			context.fillStyle = isYang ? "#FFFFFF" : "#000000";
			context.fillRect(
				(x + PADDING_BLOCKS) * blockSize,
				(y + PADDING_BLOCKS) * blockSize,
				blockSize - GRID_WIDTH,
				blockSize - GRID_WIDTH
			);
		}
		if (isTinted) {
			// Set global alpha for transparency
			context.globalAlpha = 0.85; // transparency %

			// Draw the tint color
			context.fillStyle = "#ACACAC"; // Tint color
			context.fillRect(
				(x + PADDING_BLOCKS) * blockSize,
				(y + PADDING_BLOCKS) * blockSize,
				blockSize - GRID_WIDTH,
				blockSize - GRID_WIDTH
			); // Drawing a rectangle on top of the first one
			context.globalAlpha = 1.0; // 50% transparency
		}
	};

	const isRemappingAvailableBlock = (block) =>
		block.deCount > 1 && block.isYang !== (playerInfo?.side === "yang");

	const drawAimScope = (context, { x, y }) => {
		if ((!x && x !== 0) || (!y && y !== 0)) return;
		if (canvasRender?.aim === "none") return;
		context.strokeStyle = "#7EF0FF"; // ("#7EF0FF80");
		// context.lineWidth = OUTLINE_WIDTH * 2;
		context.lineWidth = OUTLINE_WIDTH;
		context.strokeRect(
			(x + PADDING_BLOCKS) * blockSize,
			(y + PADDING_BLOCKS) * blockSize,
			blockSize - OUTLINE_WIDTH,
			blockSize - OUTLINE_WIDTH
		);

		context.lineWidth = OUTLINE_WIDTH;
		const centerX = (x + PADDING_BLOCKS) * blockSize + blockSize / 2;
		const centerY = (y + PADDING_BLOCKS) * blockSize + blockSize / 2;
		context.beginPath();
		context.strokeStyle = "rgba(126, 240, 255, 0.5)";
		context.moveTo(centerX, PADDING_BLOCKS * blockSize); // Start at the top of the canvas
		context.lineTo(centerX, (y + PADDING_BLOCKS) * blockSize - SCOPE_GAP); // Draw a line down to the top of the block
		context.moveTo(
			centerX,
			(y + PADDING_BLOCKS + 1) * blockSize + SCOPE_GAP - 1
		); // Move to the bottom of the block
		context.lineTo(centerX, (height + PADDING_BLOCKS) * blockSize); // Draw a line down to the bottom of the canvas
		context.moveTo(PADDING_BLOCKS * blockSize, centerY); // Move to the left of the canvas
		context.lineTo((x + PADDING_BLOCKS) * blockSize - SCOPE_GAP, centerY); // Draw a line to the left of the block
		context.moveTo(
			(x + PADDING_BLOCKS + 1) * blockSize + SCOPE_GAP - 1,
			centerY
		); // Move to the right of the block
		context.lineTo((width + PADDING_BLOCKS) * blockSize, centerY); // Draw a line to the right of the canvas
		context.stroke(); // Apply the path to the canvas

		// Draw the coordinates
		const bottomY = (height + PADDING_BLOCKS) * blockSize + blockSize / 2;
		const rightX = (width + PADDING_BLOCKS) * blockSize + blockSize / 2;
		context.fillStyle = "#7EF0FF"; // Set the text color
		context.font = "16px Thin Space Mono, sans-serif;"; // Set the font size and family

		// Draw the width at the centerY (right)
		const rightText = `V${y + 1}`;
		const rightTextWidth = context.measureText(rightText).width;
		context.fillText(rightText, rightX, centerY + blockSize / 4);
		// Draw the width at the centerY (left)
		context.fillText(
			rightText,
			PADDING_BLOCKS * blockSize - rightTextWidth - 8,
			centerY + blockSize / 4
		);
		const topText = `H${x + 1}`;
		const topTextWidth = context.measureText(topText).width;
		context.fillText(
			topText,
			centerX - topTextWidth / 2,
			PADDING_BLOCKS * blockSize - 8
		);
		// Draw the height at the centerX (bottom)
		context.fillText(topText, centerX - topTextWidth / 2, bottomY + 8);
	};

	const setUpCanvas = (canvas) => {
		const context = canvas.getContext("2d");

		const blocksWrapper = canvas.parentElement;
		const paddedWidth = width + PADDING_BLOCKS * 2;
		const paddedHeight = height + PADDING_BLOCKS * 2;
		const blockSizeCalc = Math.round(
			Math.min(
				blocksWrapper.offsetWidth / paddedWidth,
				blocksWrapper.offsetHeight / paddedHeight
			)
		);
		setBlockSize(() => blockSizeCalc);

		canvas.width = blockSizeCalc * paddedWidth;
		canvas.height = blockSizeCalc * paddedHeight;

		const devicePixelRatio = window.devicePixelRatio || 1;
		const backingStoreRatio =
			context.webkitBackingStorePixelRatio ||
			context.mozBackingStorePixelRatio ||
			context.msBackingStorePixelRatio ||
			context.oBackingStorePixelRatio ||
			context.backingStorePixelRatio ||
			1;
		const ratio = devicePixelRatio / backingStoreRatio;

		const oldBlocksWidth = canvas.width;
		const oldBlocksHeight = canvas.height;
		if (devicePixelRatio !== backingStoreRatio) {
			canvas.width = oldBlocksWidth * ratio;
			canvas.height = oldBlocksHeight * ratio;

			updatePanelWidth(
				canvas.style.width.replace("px", "") - PADDING_BLOCKS * 2 * blockSize
			);
			canvas.style.width = oldBlocksWidth + "px";
			canvas.style.height = oldBlocksHeight + "px";

			context.scale(ratio, ratio);
		}
		canvas.style.width = oldBlocksWidth + "px";
		canvas.style.height = oldBlocksHeight + "px";
		updatePanelWidth(
			canvas.style.width.replace("px", "") - PADDING_BLOCKS * 2 * blockSize
		);
	};

	const drawBlocks = (context) => {
		for (let y = 0; y < height; y++) {
			for (let x = 0; x < width; x++) {
				const blockIndex = y * width + x;
				const blockInfo = blockInfos[blockIndex];

				// isTinted:
				// 1.uiMode=yourChings&& all not yours
				// 2.uiMode=remapping&& all not deCount>1&&enemy
				// const isTinted =
				// 	uiMode === modes.yourChings &&
				// 	yourChings &&
				// 	!Object.keys(yourChings).includes(blockIndex.toString());

				if (!blockInfo) {
					drawBlock(context, x, y, null, 0, false);
					continue;
				}
				const isTinted =
					uiMode === modes.yourChings || uiMode === modes.yourChingSelected
						? yourChings &&
						  !Object.keys(yourChings).includes(blockIndex.toString())
						: uiMode === modes.remapping
						? !isRemappingAvailableBlock(blockInfo)
						: false;

				drawBlock(context, x, y, blockInfo.isYang, blockInfo.deCount, isTinted);
			}
		}
	};
	useEffect(() => {
		const canvas = canvasRef.current ? canvasRef.current : replayCanvas.current;
		setUpCanvas(canvas);
		const context = canvas.getContext("2d");

		drawBlocks(context);

		// вышли за рамки канваса |hoverBlock - навелся пользователь сам NOT SAVE
		// такой же+btnclose |selectedChing - кликнувшийся чинг, который надо хранить SAVE
		// такой же+ching click |myHoveredChing - навели в yourChings на свой блок NOT SAVE
		// btnclose |myChingSelected некий блок который сейчас выбран из своих SAVE
		// btnclose |urlChing блок который выделен после перехода человека по ссылке SAVE
		// 2 последних на самом деле одно и то же.
		// Просто 1 по ссылке а 2 по клику

		// ПО ФАКТУ ВЕСЬ ЭТОТ ПРОЦЕСС ЭТО
		// decideBlockToAim
		// а тогда мб и ок что просто все желающие ебашат свое новое значение
		// {x, y} и тот кто ласт записал и отображается да и все
		// никаких механик запоминания что там существовало не предвидится

		// по факту рисовать все одинаково, главное определить правильный x y
		// вся проблема uiMode здесь, что он безусловно решит проблему смены
		// и проверки состояния
		// но не решает проблему данных. Возможно стоит сделать recoil state по типу {
		// mode: canvasRenderMode
		// {x, y}
		// } который изменяется по всему приложению
		// if (!!myBlockSelected) {
		// 	drawAimScope(context, convertIdToXY(myBlockSelected.chingId));
		// }

		// selectedAim может быть: {x,y}, null, "none"
		// hoverAim может быть: {x,y} null
		// если есть selectedAim то он
		// если selectedAim null то hoverAim
		// если selectedAim 'none' то
		const selectedAim = canvasRender?.aim;
		// if (selectedAim === "none") return;
		// console.log("selectedAim", selectedAim);
		const hoverAim = canvasRender?.hoverAim
			? canvasRender?.hoverAim
			: hoveredByMouseBlock;
		// console.log("hoverAim", hoverAim);
		// const coords = canvasRender?.aim ? canvasRender.aim : hoveredByMouseBlock;
		const coords = selectedAim ? selectedAim : hoverAim;
		drawAimScope(context, { ...coords });
	});

	// useEffect(() => {
	// 	const canvas = canvasRef.current;
	// 	if (!canvas) return;
	// 	const context = canvas.getContext("2d");
	// 	const selectedAim = canvasRender?.aim;
	// 	// if (selectedAim === "none") return;
	// 	// console.log("selectedAim", selectedAim);
	// 	const hoverAim = canvasRender?.hoverAim
	// 		? canvasRender?.hoverAim
	// 		: hoveredByMouseBlock;
	// 	// console.log("hoverAim", hoverAim);
	// 	// const coords = canvasRender?.aim ? canvasRender.aim : hoveredByMouseBlock;
	// 	const coords = selectedAim ? selectedAim : hoverAim;
	// 	drawAimScope(context, { ...coords });
	// }, [canvasRender, hoveredByMouseBlock]);

	return (
		<div className="canvas-wrapper-internal">
			<canvas
				ref={canvasRef}
				onMouseMove={handleMouseMove}
				onMouseLeave={handleMouseLeave}
				onClick={handleMouseClick}
			/>
		</div>
	);
}

export default RenderEngine;
