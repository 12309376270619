import { useEffect, useState } from "react";

import handleHideClass from "../common/hide-class-helper";

// side could be "yang" or "yin" string value
function ManifestBlock({ block, playerSide, isHide, manifestBlock }) {
	const [link, setLink] = useState("");
	const [isFocused, setIsFocused] = useState(false);
	const handleManifestBlockClicked = () => {
		manifestBlock(link.trim());
	};
	const topPanelClass = isHide ? "hidden" : "visible";

	useEffect(() => {
		setLink("");
	}, [block]);

	return (
		<div className={`top-panel ${topPanelClass}`}>
			<div className={`div-5-6`}>
				<div className={"div-card-block-manifested"}>
					<div className="column-2 stretch-grid">
						<div className="stretch">
							<div>
								<p className="paragraph extra-small">
									<span className="block-label">
										H{block?.x}V{block?.y}
									</span>
									<br />
								</p>
								<p className="paragraph extra-small">
									manifestation price = {50 * 2 ** Number(block?.deCount)} matic
									<br />
								</p>
							</div>
							<p className="paragraph meta-description macfix">
								to current owner = {50 * 2 ** Number(block?.deCount)} matic
								<br />
							</p>
						</div>
						<div
							id="w-node-_3564e758-06ff-45c0-7369-6ef5d43a8a3e-54442f70"
							className="place-link-wrapper"
						>
							<div className="column-2">
								<p
									className={
										`paragraph extra-small margin-bottom ${
											isFocused ? "white-text" : ""
										}` + (link.length < 121 ? "" : " error")
									}
								>
									Place any link below:
									<br />
								</p>
								<p
									id="w-node-_3564e758-06ff-45c0-7369-6ef5d43a8a43-54442f70"
									className={
										"paragraph extra-small error" +
										handleHideClass(link.length < 121)
									}
								>
									120 length max
									<br />
								</p>
							</div>
							<div className="w-form">
								<textarea
									className={
										"link-manifestation w-input" +
										(link.length < 121 ? "" : " error")
									}
									maxLength="5000"
									name="field"
									data-name="Field"
									placeholder=""
									resize="none"
									id="field"
									value={link}
									onChange={(e) => setLink(e.target.value)}
									onFocus={() => setIsFocused(true)}
									onBlur={() => setIsFocused(false)}
								></textarea>
							</div>
						</div>
					</div>
					<button
						disabled={link.length > 120 || link.trim().length === 0}
						onClick={handleManifestBlockClicked}
						className={
							"button " +
							(link.length > 120 || link.trim().length === 0
								? "black"
								: "blue") +
							" full-width"
						}
					>
						manifest as {playerSide?.toUpperCase()} BLOCK
					</button>
				</div>
			</div>
		</div>
	);
}

export default ManifestBlock;
