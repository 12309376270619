import { useState } from "react";
import handleHideClass from "../common/hide-class-helper";

function Mapping({ isHide, manualClosed }) {
	const popupClass = isHide ? "hidden" : "visible";
	const contentClass = isHide ? "fade-out" : "fade-in";

	return (
		<div className={`top-panel ${popupClass}`}>

			<div className={"div-2-3"}>
				<div className="column-2">

					<p className={"paragraph extra-small margin-bottom full-width-grid"}>
						mapping
						<br />
					</p>
					<div className="yin-wrapper">
						<div className="demons-voted">
							<div className="vote twice"></div>
							<p className="paragraph extra-small margin-none">
								YIN potential
								<br />
							</p>
						</div>
						<div className="demons-voted">
							<div className="vote one"></div>
							<p className="paragraph extra-small margin-none">
								YIN manifested
								<br />
							</p>
						</div>
					</div>
					<div className="yang-wrapper">
						<div className="angels-voted">
							<div className="vote twice"></div>
							<p className="paragraph extra-small margin-none">
								YANG potential
								<br />
							</p>
						</div>
						<div className="angels-voted">
							<div className="vote one"></div>
							<p className="paragraph extra-small margin-none">
								YANG manifested
								<br />
							</p>
						</div>
					</div>
				</div>
			</div>
			

		</div>

	);
}

export default Mapping;
