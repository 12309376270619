// side could be "yang" or "yin" string value
function PotentiateBlock({ block, playerSide, isHide, potentiateBlock }) {
	const topPanelClass = isHide ? "hidden" : "visible";
	const handleVoteClicked = () => potentiateBlock();
	return (
		<div className={`top-panel ${topPanelClass}`}>
			<div className="div-2-3">
				<div className={"div-card-block-potential"}>
					<div>
						<p className="paragraph extra-small">
							<span className="block-label">
								H{block?.x}V{block?.y}
							</span>
							<br />
						</p>
						<p className="paragraph extra-small margin-bottom">
							conversion price = {50 * 2 ** Number(block?.deCount)} matic
							<br />
						</p>
					</div>
					<button
						className="button blue full-width"
						onClick={handleVoteClicked}
					>
						Convert to {playerSide?.toUpperCase()} potential{" "}
					</button>
				</div>
			</div>
		</div>
	);
}

export default PotentiateBlock;
