import { atom, selector } from "recoil";
import { yangYinChingsState } from "./yang-yin-chings.state";
import { blockInfosState } from "./blockInfos.state";

export const balanceState = selector({
	key: "balanceState",
	get: ({ get }) => {
		const yangYinChings = get(yangYinChingsState);

		const yangCount = Object.keys(yangYinChings.yang).length;
		const yinCount = Object.keys(yangYinChings.yin).length;

		const formula = (yang, yin) =>
			Math.tanh((32 * (yin - yang)) / (21280 - yin - yang));

		return formula(yangCount, yinCount);
	},
});
