import { atom, selector } from "recoil";
import { blockInfosState } from "./blockInfos.state";
import { playerInfoState } from "./player-info.state";

export const yourChingsState = selector({
	key: "yourChingsState",
	get: ({ get }) => {
		const blockInfos = get(blockInfosState);
		const playerInfo = get(playerInfoState);
		const chings = Object.keys(blockInfos)?.reduce((newObj, key) => {
			if (
				blockInfos[key].owner.toUpperCase() === playerInfo.address.toUpperCase()
			) {
				newObj[key] = blockInfos[key];
			}
			return newObj;
		}, {});

		return chings;
		// return Object.keys(chings).map((key) => {
		//   return parseInt(key);
		// });
	},
});
