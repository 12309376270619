import { atom } from "recoil";

export const modes = {
	activeChingSelected: "potentialing",
	mapping: "mapping",
	default: "default",
	replay: "replay",
	yourChings: "yourChings",
	manifesting: "manifesting",
	remapping: "remapping",
	remappingPanel: "remappingPanel",
	paramBlock: "paramBlock",
	yourChingSelected: "yourChingSelected",
	manifestedBlockSelected: "manifestedBlockSelected",
};

export const uiModeState = atom({
	key: "uiModeState",
	default: modes.default,
});
