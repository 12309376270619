import { useState, useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import handleHideClass from "../common/hide-class-helper";
import { uiModeState, modes } from "../state/uiMode.state";
import { gameConstsState } from "../state/game-consts.state";
import { playerInfoState } from "../state/player-info.state";
import { blockSelectedLoadedState } from "../state/block-selected-loaded.state";

// side could be "yang" or "yin" string value
function RemappingPanel({ manifestBlock }) {
	const [uiMode, setUiMode] = useRecoilState(uiModeState);
	const isHide = uiMode !== modes.remappingPanel;
	const playerInfo = useRecoilValue(playerInfoState);
	const [blockSelectedLoaded, setBlockSelectedLoaded] = useRecoilState(
		blockSelectedLoadedState
	);
	const [gameConsts, setGameConsts] = useRecoilState(gameConstsState);

	const [link, setLink] = useState("");
	const [isFocused, setIsFocused] = useState(false);
	const handleManifestBlockClicked = () => {
		manifestBlock(link.trim());
	};
	const topPanelClass = isHide ? "hidden" : "visible";
	const price =
		gameConsts.baseFeeNumber * 2 ** Number(blockSelectedLoaded?.deCount);
	console.log("blockSelectedLoaded", blockSelectedLoaded);
	// to current owner ={" "}{}{" "} <br />

	useEffect(() => {
		setLink("");
	}, [blockSelectedLoaded]);
	return (
		<div className={`top-panel ${topPanelClass}`}>
			<div className={`div-5-6`}>
				<div className={"div-card-block-manifested"}>
					<div className="column-2 stretch-grid">
						<div className="stretch">
							<div>
								<p className="paragraph extra-small">
									<span className="block-label">
										H{blockSelectedLoaded?.x}V{blockSelectedLoaded?.y}
									</span>
									<br />
								</p>
								<p className="paragraph extra-small">
									remap = {price} matic
									<br />
								</p>
							</div>
							<p className="paragraph meta-description macfix">
								to current owner = {price - gameConsts.baseFeeNumber} matic
								<br />
							</p>
							<p className="paragraph meta-description macfix">
								dao imbalance fee = {gameConsts.baseFeeNumber} matic
								<br />
							</p>
						</div>
						<div
							id="w-node-_3564e758-06ff-45c0-7369-6ef5d43a8a3e-54442f70"
							className="place-link-wrapper"
						>
							<div className="column-2">
								<p
									className={
										`paragraph extra-small margin-bottom ${
											isFocused ? "white-text" : ""
										}` + (link.length < 121 ? "" : " error")
									}
								>
									Replace link below:
									<br />
								</p>
								<p
									id="w-node-_3564e758-06ff-45c0-7369-6ef5d43a8a43-54442f70"
									className={
										"paragraph extra-small error" +
										handleHideClass(link.length < 121)
									}
								>
									120 length max
									<br />
								</p>
							</div>
							<div className="w-form">
								<textarea
									className={
										"link-manifestation w-input" +
										(link.length < 121 ? "" : " error")
									}
									maxLength="5000"
									name="field"
									data-name="Field"
									placeholder=""
									resize="none"
									id="field"
									value={link}
									onChange={(e) => setLink(e.target.value)}
									onFocus={() => setIsFocused(true)}
									onBlur={() => setIsFocused(false)}
								></textarea>
							</div>
						</div>
					</div>
					<button
						disabled={link.length > 120 || link.trim().length === 0}
						onClick={handleManifestBlockClicked}
						className={
							"button " +
							(link.trim().length > 0
								? "blue"
								: playerInfo?.side === "yin"
								? "black"
								: "white") +
							" full-width"
						}
					>
						remap this BLOCK as {playerInfo?.side?.toUpperCase()}
					</button>
				</div>
			</div>
		</div>
	);
}

export default RemappingPanel;
