import { useState } from "react";

import handleHideClass from "../common/hide-class-helper";

// side could be "yang" or "yin" string value
function ChooseSidePopup({ isHide, manualClosed, sideSelected }) {
	const [selectedSide, setSelectedSide] = useState(null);
	const handleCloseClick = () => manualClosed();
	const handleSideClicked = (side) => () => setSelectedSide(side);
	const handleSideSelectedClick = () => sideSelected(selectedSide);

	return (
		<div className={"div-centered pop" + handleHideClass(isHide)}>
			<div className="popup-wrapper choose-side">
				<div className="top-align">
					<div className="div-2-3">
						<p className="paragraph">
							Choose one of the forces you are going to represent in the game.
							It&#x27;s not possible to switch sides further on in the game.
							<br />
						</p>
					</div>
					<div className="div-1-3 align-right">
						<button className="button" onClick={handleCloseClick}>
							X
						</button>
					</div>
					<div className="choose">
						<div
							className={"yang" + (selectedSide === "yang" ? " active" : "")}
							onClick={handleSideClicked("yang")}
						></div>
						<div
							className={"yin" + (selectedSide === "yin" ? " active" : "")}
							onClick={handleSideClicked("yin")}
						></div>
					</div>
					<p className="paragraph meta div-centered margin-top">Click to choose force</p>
				</div>
				{selectedSide && (
					<button
						className="button blue full-width margin-top"
						onClick={handleSideSelectedClick}
					>
						play for {selectedSide === "yang" ? "YANG" : "YIN"}
					</button>
				)}
			</div>
		</div>
	);
}

export default ChooseSidePopup;
